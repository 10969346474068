import React, {useCallback, useEffect, useState} from 'react';
import { standartColors } from '../../assets/colors/Colors';
import { Searchbar } from '../../Searchbar/Searchbar';
import {VideoRepository} from "../../Repositories/VideoRepository";
import {BACKEND_URL} from "../../assets/AppConstants";
import {I18N} from "../../i18n/i18n";
import {useLanguageState} from "../../States/LanguageState";
import {Table} from "../../UiLibrary/table/Table";
import ShowPDF from "../LearnPdf/ShowPDF";
import ShowVideo from "./ShowVideo";
import ViewBox from "../../ViewComponents/ViewBox";
import ViewBoxTab from "../../ViewComponents/ViewBoxTab";
import {useLoginState} from "../../States/UserState";
import {VideoPlayer} from "../../VideoPlayer/VideoPlayer";
import {LearnVideosType} from "../../Types/LearnVideosTypes";
import FormButton from "../../ViewComponents/FormButton";
import {Backdrop, CircularProgress, useTheme} from "@mui/material";
import RunQuiz from "../../QuizEditor/RunQuiz";
import Select from "react-select";
import LanguageExporter from "../../Helpers/LanguageExporter";

const videoRepository = new VideoRepository();

type Props = {
    style: any
    isMobile: () => boolean
}


export function LearnVideos(props: Props) {


    //Setting states
    const theme = useTheme();
    const [videoOpen, setVideoOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState<LearnVideosType | null>(null);
    const [contents, setContents] = useState<LearnVideosType[]>([]);
    const language = useLanguageState(state => state.language);
    const [isListOpen, setIsListOpen] = useState(false);
    const authState = useLoginState();
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [activeData, setActiveData] = useState('open'); // open, seen, done, all

    //Scrolling top when page opens
    useEffect(() => {

        window.scrollTo({
            behavior: "smooth",
            top: 0
        });

    }, []);

    useEffect(() => {
        setSelectedVideo(null);
        setVideoOpen(false);
    }, [props]);



    //Receiving content data
    const getContents = useCallback( async (contentId = null) => {

        setLoading(true);
        const contentGet = await videoRepository.getUserContent();

        if(contentId !== null){
            const found = contentGet.find((content) =>  content.id === contentId);
            if(found){
                setSelectedVideo(found);
            }
        }

        setContents(contentGet);

        setLoading(false);
    }, []);


    //Calling receive function when page opens
    useEffect(() => {
        getContents().then(undefined);
    }, [getContents]);

    // Scrolling to top
    const scrollTop = useCallback(() => {
        window.scrollTo({
            behavior: "smooth",
            top: 0
        });
    },[]);

    const openVideo = useCallback((content: any) => {
        setVideoOpen(true);
        setSelectedVideo(content);
        window.scrollTo({
            behavior: "smooth",
            top: 0
        });
    }, []);

    useEffect(() => {


        if(window.location.hash.includes("video=")){
            setIsLoading(true);
            if(contents.length > 0){
                const data = window.location.hash.split("video=");
                const videoID = data[1];

                const videoData = contents.find((video) => video.id === Number(videoID));
                if(videoData){
                    openVideo(videoData);
                    removeHash()
                }
                setIsLoading(false);
            }

        }


    }, [contents]);

    const removeHash =()  => {
        var scrollV, scrollH, loc = window.location;
        if ("pushState" in window.history)
            window.history.pushState("", document.title, loc.pathname + loc.search);
        else {
            // Prevent scrolling by storing the page's current scroll offset
            scrollV = document.body.scrollTop;
            scrollH = document.body.scrollLeft;

            loc.hash = "";

            // Restore the scroll offset, should be flicker free
            document.body.scrollTop = scrollV;
            document.body.scrollLeft = scrollH;
        }
    }

    const tableColumns = (): { name: string, dataName: string, type: 'BOOLEAN' | 'TEXT' | undefined, callback?: (item) => void }[] => {
        return [
            {name: 'ID', dataName: 'id', type: 'TEXT', callback: (item) => {setVideoOpen(true); setSelectedVideo(item)}},
            {name: I18N('title', language), dataName: 'title', type: 'TEXT'},
            {name: I18N('trophy', language), dataName: 'trophies.name', type: 'TEXT'},
            {name: I18N('watched', language), dataName: 'seen_videos', type: 'BOOLEAN', callback: (item) => {setVideoOpen(true); setSelectedVideo(item)}}
        ];
    }


    const ContentHolder = useCallback((content: any) => {

        return (
            <div onClick={() => openVideo(content)} key={content.id} className="contentInner">
                <p className="content-text">{LanguageExporter(content, language, 'video_titles', 'title', 'title')} {content.seen_videos.length > 0 && <div className="finished" />}</p>
                <img  className="content-picture" src={BACKEND_URL + content.image} alt={LanguageExporter(content, language, 'video_titles', 'title', 'title')} />
            </div>
        )
    }, [language]);


    const NoContentHolder =  useCallback((type: 'toView' | 'extraContent' | 'allContent' | 'viewed') => {
        return (
            <div className="alert alert-info">
                <p>
                    {I18N('noContent', language, [{key: 'contentName', value: I18N(type, language)}])}
                </p>

            </div>
        )
    }, [language]);

    const backToContentList = useCallback(() => {

        setVideoOpen(false);
        setSelectedVideo(null);
        getContents().then(undefined);
       scrollTop();

    },[]);

    const filterContent = useCallback((type: 'toView' | 'extra' | 'all' | 'finished') => {

        const data = contents.filter((i) => {
            let isExists = false;

            i.videos_companies.forEach((a) => {
               if(authState.company.includes(a.company_id)){
                   isExists = true;
               }
            });

            return isExists;
        });

        switch (type){
            case "toView": {
               return data.filter((vid) => {
                    if(vid.seen_videos.length > 0) {
                        return false;
                    }else if(vid.inPlan !== undefined){
                        return true;
                    }else if(vid.video_departments.findIndex((inDp) => inDp.department_id === authState.data?.innerData.department) !== -1){
                        return true
                    }else if(vid.requiredForAll){
                        return true;
                    }else if(vid.visibleForAll){
                        return false;
                    }
                    return false;
                }).sort((a, b) => a.title.localeCompare(b.title))
            }
            case "extra": {
                return data.filter((vid) => {
                    if(vid.seen_videos.length > 0){
                        return false;
                    }else if(vid.visibleForAll && vid.video_departments.findIndex((inDp) => inDp.department_id === authState.data?.innerData.department) === -1 && !vid.requiredForAll){
                        return true
                    }else if(vid.requiredForAll){
                        return false;
                    }
                    return false;
                }).sort((a, b) => a.title.localeCompare(b.title))
            }

            case "finished": {
                return data.filter((vid) => vid.seen_videos.length > 0).sort((a, b) => a.title.localeCompare(b.title))
            }
            case "all":
            default: {
                return data.sort((a, b) => a.title.localeCompare(b.title));
            }
        }

    }, [contents]);

    const onFinishQuiz = useCallback(async (isFail: boolean, contentId: number) => {
        if(isFail){
            await getContents(contentId);
        }else{
            backToContentList();
        }

    }, [backToContentList, getContents]);

    const callQuiz = useCallback(async (contentId) => {
        await getContents(contentId);
    }, [getContents]);

    const videoShower = useCallback(() => {
        console.log(selectedVideo.quizes);
        if(selectedVideo.quizes !== null && selectedVideo.quizes !== undefined){

            if(selectedVideo.quizes.quiz_progress.filter((quizD) => quizD.content_id === selectedVideo.id).length > 0){
                return <RunQuiz selectedVideo={selectedVideo} onFinishQuiz={onFinishQuiz} />
            }
        }


        if(selectedVideo.type === 1){
            return (
                <ShowVideo returnBack={backToContentList} callQuiz={callQuiz} video={selectedVideo} />
            )
        }if(selectedVideo.type === 4){
            return <RunQuiz selectedVideo={selectedVideo} onFinishQuiz={onFinishQuiz} />
        }else{
            return (
                <React.Fragment>
                    {selectedVideo.type === 2 ? (
                        <ShowPDF callQuiz={callQuiz} returnBack={backToContentList} pdf={selectedVideo} content={selectedVideo.user_content_data.length > 0 ? JSON.parse(selectedVideo.user_content_data[0].data) : null} />
                    ): (
                        <VideoPlayer callQuiz={callQuiz} video={selectedVideo} returnBack={backToContentList} content={selectedVideo.user_content_data.length > 0 ? JSON.parse(selectedVideo.user_content_data[0].data) : null} />
                    )}
                </React.Fragment>
            )
        }

    }, [selectedVideo, onFinishQuiz, backToContentList, callQuiz]);


        return (
            <div style={props.style}>
                <Backdrop
                    open={isLoading}
                    style={{ color: standartColors.primaryYellow, zIndex: 1 }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Searchbar />
                <div style={styles.videoPreview.container}>
                    {!videoOpen &&
                        <div style={{ ...styles.videoPreview.header.container, flexDirection: props.isMobile() ? 'column' : 'row', }}>
                            <div style={{color: theme.palette.mode === 'dark' && '#fff'}}>{I18N('learnContent', language)}</div>
                        </div>
                    }
                    {videoOpen &&
                        <React.Fragment>
                            < div style={styles.videoPreview.header.container}>
                                <FormButton text={''} backButton={true} smallButton={true} onClick={backToContentList} isLoading={false} />
                            </div>
                            <ul className="breadCrumb" style={{marginTop: -50, marginLeft: 30, marginBottom: 20, color: theme.palette.mode === "dark" && "#fff"}}>
                                <li onClick={backToContentList}>{I18N('learnContent', language)}</li>
                                <li className="divider" />
                                <li>{LanguageExporter(selectedVideo, language, 'video_titles', 'title', 'title')}</li>
                            </ul>
                        </React.Fragment>


                    }
                    {!videoOpen &&

                        <>

                            <div style={{width: 150}}>
                                <Select styles={{control: base => ({
                                        ...base,
                                        width: 150,
                                        marginTop: 20,
                                        marginLeft: 0,
                                        height: 30,
                                        minHeight: 55
                                    })}} onChange={(value, action) => {
                                        setActiveData(value.value)
                                }} value={{label: I18N(activeData, language), value: activeData}} options={[{label: I18N('open', language), value: 'open'}, {label: I18N('seen', language), value: 'seen'}, {label: I18N('extra', language), value: 'extra'}, {label: I18N('all', language), value: 'all'}]}>

                                </Select>
                            </div>

                            <ViewBox>
                                {activeData === 'open' && (
                                    <div className="flex-content">
                                        {(loading || filterContent('toView').length > 0) ? (
                                            <React.Fragment>
                                                {isListOpen ? (
                                                    <Table
                                                        columns={tableColumns()}
                                                        rows={filterContent('toView')} />
                                                ) : filterContent('toView').map((content) => ContentHolder(content))}
                                            </React.Fragment>
                                        ): NoContentHolder('toView')}
                                    </div>
                                )}

                                {activeData === 'seen' && (
                                    <div className="flex-content">
                                        {(loading || filterContent('finished').length > 0) ? (
                                            <React.Fragment>
                                                {isListOpen ? (
                                                    <Table columns={tableColumns()} rows={filterContent('finished')} />
                                                ): filterContent('finished').map((content) => ContentHolder(content))}
                                            </React.Fragment>
                                        ): (NoContentHolder('allContent'))}
                                    </div>
                                )}


                                {activeData === 'all' && (
                                    <div className="flex-content">
                                        {(loading || filterContent('all').length > 0) ? (
                                            <React.Fragment>
                                                {isListOpen ? (
                                                    <Table columns={tableColumns()} rows={filterContent('all')} />
                                                ) : filterContent('all').map((content) => ContentHolder(content))}
                                            </React.Fragment>
                                        ): (NoContentHolder('allContent'))}
                                    </div>
                                )}

                                {activeData === 'extra' && (
                                    <div className="flex-content">
                                        {(loading || filterContent('extra').length > 0) ? (
                                            <React.Fragment>
                                                {isListOpen ? (<Table columns={tableColumns()} rows={filterContent('extra')} />) : filterContent('extra').map((content) => ContentHolder(content))}
                                            </React.Fragment>
                                        ): NoContentHolder('extraContent')}
                                    </div>
                                )}

                                {/*<React.Fragment>*/}
                                {/*    <ViewBoxTab tabs={[{name: I18N('toView', language), content: (*/}
                                {/*            <ViewBox>*/}
                                {/*                <div className="flex-content">*/}
                                {/*                    {(loading || filterContent('toView').length > 0) ? (*/}
                                {/*                        <React.Fragment>*/}
                                {/*                            {isListOpen ? (*/}
                                {/*                                <Table*/}
                                {/*                                    columns={tableColumns()}*/}
                                {/*                                    rows={filterContent('toView')} />*/}
                                {/*                            ) : filterContent('toView').map((content) => ContentHolder(content))}*/}
                                {/*                        </React.Fragment>*/}
                                {/*                    ): NoContentHolder('toView')}*/}
                                {/*                </div>*/}
                                {/*            </ViewBox>*/}
                                {/*        )},{name: I18N('viewed', language), content: (*/}
                                {/*            <ViewBox>*/}
                                {/*                <div className="flex-content">*/}
                                {/*                    {(loading || filterContent('finished').length > 0) ? (*/}
                                {/*                        <React.Fragment>*/}
                                {/*                            {isListOpen ? (*/}
                                {/*                                <Table columns={tableColumns()} rows={filterContent('finished')} />*/}
                                {/*                            ): filterContent('finished').map((content) => ContentHolder(content))}*/}
                                {/*                        </React.Fragment>*/}
                                {/*                    ): (NoContentHolder('allContent'))}*/}
                                {/*                </div>*/}
                                {/*            </ViewBox>*/}
                                {/*        )},{name: I18N('extraContent', language), content: (*/}
                                {/*            <ViewBox>*/}
                                {/*                <div className="flex-content">*/}
                                {/*                    {(loading || filterContent('extra').length > 0) ? (*/}
                                {/*                        <React.Fragment>*/}
                                {/*                            {isListOpen ? (<Table columns={tableColumns()} rows={filterContent('extra')} />) : filterContent('extra').map((content) => ContentHolder(content))}*/}
                                {/*                        </React.Fragment>*/}
                                {/*                    ): NoContentHolder('extraContent')}*/}
                                {/*                </div>*/}
                                {/*            </ViewBox>*/}
                                {/*        )},{name: I18N('allContent', language), content: (*/}
                                {/*            <ViewBox>*/}
                                {/*                <div className="flex-content">*/}
                                {/*                    {(loading || filterContent('all').length > 0) ? (*/}
                                {/*                        <React.Fragment>*/}
                                {/*                            {isListOpen ? (*/}
                                {/*                                <Table columns={tableColumns()} rows={filterContent('all')} />*/}
                                {/*                            ) : filterContent('all').map((content) => ContentHolder(content))}*/}
                                {/*                        </React.Fragment>*/}
                                {/*                    ): (NoContentHolder('allContent'))}*/}
                                {/*                </div>*/}
                                {/*            </ViewBox>*/}
                                {/*        )}]} onTabChange={() => undefined} />*/}

                                {/*</React.Fragment>*/}

                            </ViewBox>

                        </>

                    }
                    {videoOpen &&
                        <React.Fragment>
                            {videoShower()}
                        </React.Fragment>
                    }
                </div>

            </div >
        )
}

const styles = {

    videoPreview: {
        container: {height: '100%', width: '93%', display: 'flex', marginLeft: 25, flexDirection: 'column' } as React.CSSProperties,
        header: {
            container: { height: 80, width: 50, display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: standartColors.black, fontWeight: 'bold' } as React.CSSProperties,
            buttom: {}
        },
        filterButton: { backgroundColor: standartColors.primaryYellow, width: 200, height: 45, borderRadius: 10, display: 'flex', justifyContent: 'space-around', alignItems: 'center' },
        videoGallery: {
            container: { height: 500, width: '100%', display: 'flex', flexWrap: 'wrap', } as React.CSSProperties,
            video: { height: 363, width: 366, marginTop: 50, marginLeft: 50, borderRadius: 10, } as React.CSSProperties,
            videoMobile: { marginTop: 50, borderRadius: 10, },
            previewPic: { height: '100%', width: '100%', borderRadius: 10 }
        },

    },
    videoName:{
        position: "relative",
        top: '95%',
        marginLeft: 20,
        width: '50%',
        padding: 10,
        backgroundColor: "rgba(0,0,0,.4)",
        color: "#fff",
        fontWeight: "bold",
        fontSize: 22,
    } as React.CSSProperties,
    videoPlayer: {
        container: { height: '100%', width: '80%',  },
        iframe: { height: '100%', width: '120%', borderRadius: 10 }
    }
}
