import {useCallback, useEffect, useMemo, useState} from 'react';
import 'swiper/swiper-bundle.css';
import './App.css';
import { Login } from './Login/Login';
import {Route, useHistory} from "react-router-dom";
import { Dashboard } from './Dashboard/Dashboard';
import React from 'react';
import SwiperCore, {
  FreeMode,
  Navigation,
  Pagination,
  Scrollbar
} from 'swiper';

import {COOKIE_TOKEN, expireCookie, getToken, setCookie} from "./Repositories/Cookies";
import {useAuthServiceState} from "./States/AuthServiceState";
import axios from "axios";
import {I18N} from "./i18n/i18n";
import {UsersRepository} from "./Repositories/UsersRepository";
import {useLanguageState} from "./States/LanguageState";
import ColorModeContext from './ColorMode';
import ComplinessTheme from "./ComplinessTheme";
import {
  unstable_createMuiStrictModeTheme as createTheme,
  CssBaseline,
  ThemeProvider
} from '@mui/material';
import {LogsRepository} from "./Repositories/LogsRepository";
import EGRCContentHome from "./EGRCContent/EGRCContentHome";
import {BACKEND_URL} from "./assets/AppConstants";
import {useSnackbar} from "notistack";
import AutoLogout from "./AutoLogout";
const logRepository = new LogsRepository();
SwiperCore.use([FreeMode, Navigation, Pagination, Scrollbar]);

axios.interceptors.request.use((request) => {

  //@ts-ignore
  if(request.url.includes(window.env.KEYCLOAK_URL)){
    if(request.headers['x-auth-company']){
      delete request.headers['x-auth-company']
    }
  }

  return request;

});


axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if(error.config.url !== '/logger'){
    logRepository.post({type: 'error', message: JSON.stringify(error)}).then(undefined);
    return Promise.reject(error);
  }else{
    return Promise.reject(error);
  }


});

//@ts-ignore
export const params: {[key: string]: string} = new Proxy(new URLSearchParams(window.location.search), {
  //@ts-ignore
  get: (searchParams, prop) => searchParams.get(prop),
});
const TOKEN_REFRESH_TIMER = 50000;
const userRepository = new UsersRepository();

export default function App() {

  const {enqueueSnackbar} = useSnackbar();
  const [loggedIn, setLoggedIn] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [client, setClient] = useState(getToken() === undefined ? 'kfmdigital' : getToken());
  const [tokenMode, setTokenMode] = useState(false);
  const [egrcToken, setEgrcToken] = useState('');
  const languageState = useLanguageState();
  const setAuthService = useAuthServiceState();
  const history = useHistory();
  const login = (client, username, password) => {
    setCookie(COOKIE_TOKEN, client);
    setClient(client);
  }
  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);


  const updateWindowDimensions = () => {
    if (window.innerWidth <= 1000) {
      setIsMobile(true);
    }
  }

  useEffect(() => {

    if(params.egrcAccess !== null){
      axios.get(BACKEND_URL + '/videos/egrcData/checkToken', {params: {
          token: params.egrcAccess
        }}).then((response) => {
        if(response.status){
          setEgrcToken(params.egrcAccess);
          setTokenMode(true);
          //history.push('/egrcContent');

        }

      }).catch((err) => {
        console.log(err);
      })
    }

  }, []);


  useEffect(() => {


    if(params.authService === "true" && params.redirectUrl !== null){
      const URLREGEX = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
      if(URLREGEX.test(params.redirectUrl)){
        setAuthService.setState(true);
        setAuthService.setRedirectUrl(params.redirectUrl);
      }
    }

  }, []);


  useEffect(() => {

    if(params.egrcAccess !== null){
      axios.get(BACKEND_URL + '/videos/egrcData/checkToken', {params: {
        token: params.egrcAccess
        }}).then((response) => {
          if(response.status){
            setEgrcToken(params.egrcAccess);
            setTokenMode(true);
            //history.push('/egrcContent');

          }

      }).catch((err) => {
        console.log(err);
      })
    }

  }, []);


  const refreshAuth = useCallback(() => {
    const getRefreshToken = getToken(undefined, 'refresh_token');
    const getClient = getToken();
    if(getRefreshToken !== undefined && getClient !== undefined){
      userRepository.refreshKeycloak(getClient, getRefreshToken).then(async (response) => {
        if(response.status){
          axios.defaults.headers['Authorization'] = `Bearer ${response.data.access_token}`
          //await axios.get<any>('/users/me');
          setCookie('refresh_token', response.data.refresh_token);
          setCookie(COOKIE_TOKEN, getClient);
        }else{
          expireCookie(COOKIE_TOKEN);
          expireCookie('refresh_token');
          if(window.location.pathname === ''){}else if(window.location.pathname === '/'){}else{
            enqueueSnackbar(I18N("authExpired", languageState.language), {variant: "error"});
          }
        }
      })
    }
  }, [languageState.language]);


  //SETTING REFRESHER
  useEffect(() => {

    if(loggedIn){
      console.log("Registering Token Refresher")
      const interval = setInterval(() => {
        refreshAuth();
      }, TOKEN_REFRESH_TIMER);
      return () => {
        console.log("DEREGISTERING REFRESHER")
        clearInterval(interval);
      }
    }

  }, [loggedIn]);


  const setLogin = () => {
      setLoggedIn(true);
  }

  const [mode, setMode] = React.useState<'light' | 'dark'>('light');
  const colorMode = React.useMemo(
      () => ({
        toggleColorMode: () => {
          setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        },
      }),
      [],
  );

  const defaultTheme = useMemo(
      () => ComplinessTheme(mode),
      [mode]
  );
// @ts-ignore
  const theme = createTheme(defaultTheme);

  return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="sans-serif">
            {tokenMode ? (
               <EGRCContentHome token={egrcToken} />
            ): (
                <>
                  {!loggedIn ?
                      <Route path="/" render={() => <Login setLoggedIn={() => setLogin()} client={client} login={login} />} />
                      :
                      (
                          <AutoLogout>
                            <Dashboard isMobile={() => isMobile} logout={() => {}} />
                          </AutoLogout>
                      )

                  }
                </>
            )}

          </div>

        </ThemeProvider>
      </ColorModeContext.Provider>

  )
}



