import {useLanguageState} from "../../States/LanguageState";
import {I18N} from "../../i18n/i18n";
import SidebarMenu, {ISidebarMenuItem} from "./SidebarMenu";
import CompaniesIcon from '../../assets/companiesicon.svg';
import DepartmentsIcon from  '../../assets/departmentsicon.svg';
import UsersIcon from  '../../assets/usericon.svg';
import CategoriesIcon from  '../../assets/categoriesicon.svg';
import LearnContentIcon from  '../../assets/learncontenticon.svg';
import LearningPlanIcon from  '../../assets/learninplansicon.svg';
import TrophiesIcon from  '../../assets/trophiesicon.svg';
import Userlists from  '../../assets/userlistsicon.svg';
import AnalyticsIcon from '../../assets/analyticsIcon.svg'
import QuizEditor from  '../../assets/quiz-editoricon.svg';
import PasswordPolicyIcon from  '../../assets/passwordpolicyicon.svg';
import ApiKeys from  '../../assets/apikeysicon.svg';
import PermissionMatrixIcon from '../../assets/permissionmatrix.svg';
import MailIcon from '../../assets/email.svg';
import {useCallback, useEffect, useState} from "react";
import {getToken} from "../../Repositories/Cookies";
import {useLoginState} from "../../States/UserState";
import {useHistory, useLocation} from "react-router-dom";
import MultiPermissionCheck from "../../Helpers/MultiPermissionCheck";

const ConfigurationsSidebarComponent = (props: {setActiveTab: (state: any) => void; active: {index: number, outIndex: number}}) => {
    const language = useLanguageState((state) => state.language);
    const clientName = getToken();
    const loginState = useLoginState();
    const history = useHistory();
    const router = useLocation();

    const [barFirst, setBarFirst] = useState<ISidebarMenuItem[]>([]);
    const [barSecond, setBarSecond] = useState<ISidebarMenuItem[]>([]);
    const [barThird, setBarThird] = useState<ISidebarMenuItem[]>([]);
    const [barFourth, setBarFourth] = useState<ISidebarMenuItem[]>([]);

    const roleList = loginState.token.resource_access[clientName].roles;

    const sidebar1 = useCallback(() => {

        if (loginState.token === null) {
            setBarFirst([]);
            return [];
        }

        const arrayOfBars: ISidebarMenuItem[] = [];

        if(MultiPermissionCheck(roleList, ['ViewAllCompanies', 'ViewCompanies'])){
            arrayOfBars.push(
                {
                    title: I18N('companies', language),
                    //icon: 'UserGroupIcon',
                    to: "companies",
                    icon: CompaniesIcon,
                },
            );
        }

        if(MultiPermissionCheck(roleList, ['ViewDepartments', 'ViewAllDepartments'])){
            arrayOfBars.push(
                {
                    title: I18N('departments', language),
                    icon: DepartmentsIcon,
                    to: "departments",
                },
            )
        }

        if(MultiPermissionCheck(roleList, ['ViewAllUsers', 'ViewUser'])){

            arrayOfBars.push(
                {
                    title: I18N('users', language),
                    icon: UsersIcon,
                    to: "users",
                },
            )

        }

        if(MultiPermissionCheck(roleList, ['query-realms'])){

            arrayOfBars.push(
                {
                    title: I18N('superadmin', language),
                    icon: UsersIcon,
                    to: "superadmin",
                },
            )

        }

        if(MultiPermissionCheck(roleList, ['ViewPermissionMatrix'])){

            arrayOfBars.push(
                {
                    title: I18N('permissionMatrix', language),
                    icon: PermissionMatrixIcon,
                    to: "permissionmatrix",
                },
            )

        }


        if(MultiPermissionCheck(roleList, ['ViewAllCategories', 'ViewCategories'])){

            arrayOfBars.push(
                {
                    title: I18N('categories', language),
                    icon: CategoriesIcon,
                    to: "categories"
                },
            )
        }

        setBarFirst(arrayOfBars);

    }, [props, language, loginState, clientName, roleList]);

    const sidebar2 = useCallback(() => {

        if (loginState.token === null) {
            setBarSecond([]);
            return [];
        }

        const arrayOfBars: ISidebarMenuItem[] = [];

        if(MultiPermissionCheck(roleList, ['ViewContent', 'ViewAllContent'])){
            arrayOfBars.push(
                {
                    title: I18N('learnContent', language),
                    icon: LearnContentIcon,
                    to: "contents",
                },
            )
        }

        if(MultiPermissionCheck(roleList, ['ViewAllLearningPlan', 'ViewLearningPlan'])){
            arrayOfBars.push(
                {
                    title: I18N('learningPlan', language),
                    icon: LearningPlanIcon,
                    to: "learningplans"
                },
            )
        }

        if(MultiPermissionCheck(roleList, ['ViewAllTrophies', 'ViewTrophies'])){
            arrayOfBars.push(
                {
                    title: I18N('trophies', language),
                    icon: TrophiesIcon,
                    to: "trophies"
                }
            )
        }

        if(MultiPermissionCheck(roleList, ['ViewList', 'ViewAllList'])){
            arrayOfBars.push(
                {
                    title: I18N('lists', language),
                    icon: Userlists,
                    to: "userlists",
                }
            )
        }

        if(MultiPermissionCheck(roleList, ['ViewAllQuiz', 'ViewQuiz'])){
            arrayOfBars.push(
                {
                    title: I18N('quizEditor', language),
                    icon: QuizEditor,
                    to: "quiz"
                }
            )
        }

        setBarSecond(arrayOfBars);

    }, [props, language, loginState, clientName, roleList]);

    const sidebar3 = useCallback(() => {

        if (loginState.token === null) {
            setBarThird([]);
            return [];
        }

        const arrayOfBars: ISidebarMenuItem[] = [];

        if(MultiPermissionCheck(roleList, ['EditPasswordTexts'])){

            arrayOfBars.push(
                {
                    title: I18N('passwordTexts', language),
                    icon: PasswordPolicyIcon,
                    to: "passwordtexts"
                }
            )

        }

        if(MultiPermissionCheck(roleList, ['EditPasswordQuality'])){

            arrayOfBars.push(
                {
                    title: I18N('passwordquality', language),
                    icon: PasswordPolicyIcon,
                    to: "passwordquality"
                }
            )

        }



        if(MultiPermissionCheck(roleList, ['CreateNotification'])){
            arrayOfBars.push(
                {
                    title: I18N('notification', language),
                    icon: PasswordPolicyIcon,
                    to: "notifications"
                }
            )
        }

        // eslint-disable-next-line no-self-compare
        if((MultiPermissionCheck(roleList, ['ViewApiKeys']))){
            arrayOfBars.push(
                {
                    title: I18N('api_keys', language),
                    icon: ApiKeys,
                    to: "apikeys"
                }
            )
        }

        setBarThird(arrayOfBars);

    }, [props, language, loginState, clientName, roleList]);


    const sidebar4 = useCallback(() => {

        if (loginState.token === null) {
            setBarFourth([]);
            return [];
        }

        const arrayOfBars: ISidebarMenuItem[] = [];

        if((MultiPermissionCheck(roleList, ['EditAnalyticsSettings']))){

            arrayOfBars.push(
                {
                    title: I18N('analytics', language),
                    icon: AnalyticsIcon,
                    to: "analytics"
                }
            )

        }

        if((MultiPermissionCheck(roleList, ['EditAnalyticsSettings']))){

            arrayOfBars.push(
                {
                    title: I18N('editMails', language),
                    icon: MailIcon,
                    to: "mails"
                }
            )

        }

        setBarFourth(arrayOfBars);

    }, [props, language, loginState, clientName, roleList]);

    useEffect(() => {

        sidebar1();
        sidebar2();
        sidebar3();
        sidebar4();
    }, [sidebar1, sidebar2, sidebar3]);


    useEffect(() => {
        console.log(router.pathname);
        if(router.pathname === '/Konfiguration'){
            const findFirstOne = barFirst.length > 0 ? barFirst[0].to : '/';
            if(findFirstOne !== '/'){
                history.push('/Konfiguration/'+findFirstOne)
            }
        }
    }, [router, barFirst, history]);


    return (
        <>
            {barFirst.length > 0 && (
                <>
                    <SidebarMenu items={barFirst} />
                    <div style={{ height: '20px' }} />
                </>
            )}

            {barSecond.length > 0 && (
                <>
                    <SidebarMenu items={barSecond} />
                    <div style={{ height: '20px' }} />
                </>
            )}

            {barThird.length > 0 && (
                <>
                    <SidebarMenu items={barThird} />
                    <div style={{ height: '20px' }} />
                </>
            )}

            {barFourth.length > 0 && (
                <>
                    <SidebarMenu items={barFourth} />
                    <div style={{ height: '20px' }} />
                </>
            )}

        </>
    );
};
export default ConfigurationsSidebarComponent;