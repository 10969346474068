import { TextField, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { I18N } from '../i18n/i18n';
import { useLanguageState } from '../States/LanguageState';
import ViewBox from '../ViewComponents/ViewBox';
import Select from 'react-select';
import { colorForSelect } from '../ComplinessTheme';
import { CompanyRepository } from '../Repositories/CompanyRepository';
import { useBreadCrumb } from '../States/BreadCrumbState';
import { useLoginState } from '../States/UserState';
import SelectAutocomplete from '../ViewComponents/SelectAutoComplete';
import { UsersRepository } from '../Repositories/UsersRepository';

const companyRepository = new CompanyRepository();
const userRepository = new UsersRepository();

type Props = {
  editMode?: any;
  onNameChange: (name: string) => void;
  onResponsibleChange: (responsible: any[]) => void;
  onCompaniesChange: (item: any) => void;
  onFinish: () => void;
  onReturnBackToView: () => void;
};

export function DepartmentOperation(props: Props) {
  const language = useLanguageState((state) => state.language);
  const theme = useTheme();
  const [name, setName] = useState<string>('');
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState<any[]>([]);
  const [responsible, setResponsible] = useState([]);
  const [company, setCompany] = useState(undefined);
  const authService = useLoginState();

  const breadCrumb = useBreadCrumb();

  const loadCompanies = useCallback(async () => {
    const subList = await companyRepository.getSubCompanies();
    const userComp = subList;
    setCompanies(userComp);
  }, [authService]);

  const loadUsers = useCallback(async () => {
    const userList = await userRepository.getAll();
    //@ts-ignore
    const filteredList = userList.map((x) => x);

    setUsers(filteredList);
  }, []);

  useEffect(() => {
    breadCrumb.setBackButton(props.onReturnBackToView);
    breadCrumb.setTitle(
      props.editMode ? props.editMode.name : I18N('createDepartment', language)
    );
    breadCrumb.setAdditionalCrumb({
      title: props.editMode
        ? props.editMode.name
        : I18N('createDepartment', language),
      onClick: undefined
    });
    breadCrumb.setChildren([
      {
        title: props.editMode
          ? I18N('updateDepartment', language)
          : I18N('createDepartment', language),
        onClick: props.onFinish,
        icon: 'save',
        color: 'lightGreen'
      }
    ]);
  }, [props.editMode, language, props.editMode, props.onFinish]);

  useEffect(() => {
    if (props.editMode !== null) {
      setName(props.editMode.name);
      setCompany(
        props.editMode.departments_companies.map((i) => {
          return {
            label: i.company.name,
            value: i.company.id
          };
        })
      );
      setResponsible(
        props.editMode.responsible?.map((i) => {
          return {
            label: `${i.user.firstName} ${i.user.lastName} (${i.user.emailAddress})`,
            value: i.user.id
          };
        })
      );
    }
  }, [props.editMode]);

  useEffect(() => {
    props.onNameChange(name);
    props.onCompaniesChange(company);
    props.onResponsibleChange(responsible);
  }, [name, props, company, responsible]);

  useEffect(() => {
    loadCompanies().then(undefined);
    loadUsers().then(undefined);
  }, []);

  return (
    <>
      <ViewBox title={I18N('generalInformation', language)}>
        <TextField
          fullWidth
          label={I18N('title', language)}
          className="marginTop20"
          value={name}
          onChange={(text) => setName(text.target.value)}
        />
      </ViewBox>

      <ViewBox title={I18N('companies', language)}>
        <SelectAutocomplete
          multiple={true}
          maxTags={3}
          options={companies.map((comp) => {
            return { value: comp.id, label: comp.name };
          })}
          value={company}
          onChange={(data) => setCompany(data)}
        />
      </ViewBox>

      <ViewBox title={I18N('responsible', language)}>
        <SelectAutocomplete
          multiple={true}
          maxTags={3}
          options={users.map((u) => {
            return {
              value: u.id,
              label: `${u.firstName} ${u.lastName} (${u.emailAddress})`
            };
          })}
          value={responsible}
          onChange={(data) => setResponsible(data)}
        />
      </ViewBox>
    </>
  );
}
