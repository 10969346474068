import React, {useCallback, useEffect, useState} from 'react';
import { UserType } from "../Types/UserType";
import TrophyList from "./TrophyList";
import { I18N } from "../i18n/i18n";
import { useLanguageState } from "../States/LanguageState";
import { TrophiesRepository } from "../Repositories/TrophiesRepository";
import { TrophyOperation } from "./TrophyOperation";
import {useBreadCrumb} from "../States/BreadCrumbState";
import {useLocation} from "react-router-dom";
import {useSnackbar} from "notistack";


const trophyRepository = new TrophiesRepository();


type Props = {
    style: any;
    isMobile: () => boolean;
}



export function TrophyTab(props: Props) {
    const [createOpen, setCreateOpen] = useState<boolean>(false);
    const [editTrophy, setEditTrophy] = useState<null | UserType>(null);
    const language = useLanguageState(state => state.language);
    const [formLoading, setFormLoading] = useState(false);
    const breadCrumb = useBreadCrumb();
    const [name, setName] = useState('');
    const {enqueueSnackbar} = useSnackbar();


    const location = useLocation();
    const [mountKey, setMountKey] = useState(undefined);

    useEffect(() => {
        if(location.key !== undefined){
            if(location.key !== mountKey){
                onReturnBackToView();
            }
        }
        setMountKey(location.key);
    }, [location, mountKey]);

    const scrollTop = useCallback(() => {
        window.scrollTo({
            behavior: "smooth",
            top: 0
        });
    }, []);

    const backToTrophiesTable = () => {
        setCreateOpen(false);
        setEditTrophy(null);
        scrollTop();
    }


    const saveTrophy = useCallback(async () => {
        if (name === '') {
            enqueueSnackbar(I18N('pleaseFill', language, [{ key: 'name', value: I18N('trophy', language) }]), {variant: "error"});
            return;
        }

        setFormLoading(true);
        if (editTrophy === null) {
            const create = await trophyRepository.post({ name: name });

            if (create instanceof Error) {
                setFormLoading(false);
                enqueueSnackbar(I18N("unexpectedError", language,), {variant: "error"});
            } else {
                setFormLoading(false);
                enqueueSnackbar(I18N('successfullyCreatedReturning', language, [{ key: 'name', value: I18N('trophy', language) }]), {variant: "success"});
                onReturnBackToView()

            }
        } else {
            const update = await trophyRepository.update({ name: name }, Number(editTrophy.id));
            if (update instanceof Error) {
                setFormLoading(false);
                enqueueSnackbar(I18N("unexpectedError", language,), {variant: "error"});
            } else {
                setFormLoading(false);
                enqueueSnackbar(I18N('successfullyUpdatedReturning', language, [{ key: 'name', value: I18N('trophy', language) }]), {variant: "success"});

                onReturnBackToView();

            }
        }

        setFormLoading(false);

    }, [name, editTrophy, language]);


    const onReturnBackToView = useCallback(() => {
        breadCrumb.setBackButton(null);
        breadCrumb.setTitle(I18N('trophies', language))
        breadCrumb.setCrumb([{title: I18N("configuration", language), onClick: undefined}, {
            title: I18N('trophies', language),
            onClick: onReturnBackToView
        }]);
        breadCrumb.setChildren([
            {
                key: "createTrophies",
                title: I18N('new', language),
                onClick: () => {
                    setCreateOpen(!createOpen);
                    scrollTop();
                },
                color: 'dark',
                icon: "plus"
            }
        ])
        backToTrophiesTable();
    }, [language]);

    useEffect(() => {
        onReturnBackToView();
    }, [])

    return (
        <div style={props.style}>
            {!createOpen ?
                <TrophyList editMode={(item) => {
                    setEditTrophy(item);
                    setCreateOpen(true);
                    scrollTop();
                }} />
                : (
                    <TrophyOperation onNameChange={setName} onFinish={saveTrophy} backCallback={onReturnBackToView} editMode={editTrophy} />
                )}
        </div>
    )

}
