import { I18N } from "../i18n/i18n";
import ViewBox from "../ViewComponents/ViewBox";
import React, {useCallback, useEffect, useState} from "react";
import { useLanguageState } from "../States/LanguageState";
import {VideoRepository} from "../Repositories/VideoRepository";
import moment from "moment/moment";
import UserAnalyticsSingle from "./UserAnalyticsSingle";
import FormButton from "../ViewComponents/FormButton";
import TableMui from "../ViewComponents/TableMui";
import {useBreadCrumb} from "../States/BreadCrumbState";
import {useHistory} from "react-router-dom";
import ShowIcon from "../assets/show.svg";
import HideIcon from "../assets/hide.svg";
import {useGlobalSettingsState} from "../States/GlobalSettings";
import archivedIcon from '../assets/archiveBox.svg';
const videoRepository = new VideoRepository();


export default function UserAnalytics(props: {backHome: () => void;}) {

    const language = useLanguageState(state => state.language);
    const [watchList, setWatchList] = useState(null);
    const [videos, setVideos] = useState(null);
    const [activeView, setActiveView] = useState('userHome');
    const [selectedUser, setSelectedUser] = useState(null);
    const [loading, setLoading] = useState(false);

    const breadCrumb = useBreadCrumb();
    const history = useHistory();
    const [showUserNames, setShowUserNames] = useState(false);
    const [buttonActivated, setButtonActivated] = useState(true);
    const globalSettings = useGlobalSettingsState(state => state.settings);


    useEffect(() => {

        const findPsudeo = globalSettings.findIndex((i) => i.key === "psudeo");
        const findAnon = globalSettings.findIndex((i) => i.key === "anon");

        if(findAnon !== -1){
            if(Number(globalSettings[findAnon].value)){
                setShowUserNames(false);
                setButtonActivated(false);
            }else{
                setButtonActivated(true);
                if(findPsudeo !== -1){
                    setShowUserNames(!Number(globalSettings[findPsudeo].value))
                }else{
                    setShowUserNames(false);
                }
            }
        }else{
            setButtonActivated(true);
            if(findPsudeo !== -1){
                setShowUserNames(!Number(globalSettings[findPsudeo].value))
            }else{
                setShowUserNames(false);
            }
        }



    }, [globalSettings]);

    const loadBreadCrumb = useCallback(() => {

        breadCrumb.setCrumb([
            {
                title: I18N('analytics', language),
                onClick: undefined
            },
            {
                title: I18N('users', language),
                onClick: () => history.push('/contentAnalytics/users')
            }
        ]);

        breadCrumb.setTitle(I18N('users', language));
        breadCrumb.setBackButton(null);
        breadCrumb.setChildren([{
            title: '',
            isLoading: loading,
            isFullLoading: true,
            color: "transparent",
            onClick: undefined
        }]);

    }, [loading]);
    useEffect(() => {
        loadBreadCrumb();
    }, []);

    const loadData = useCallback(async () => {
        setLoading(true);
        const watchListData = await videoRepository.getWatchListUser();
        console.log(watchListData);
        setVideos(watchListData.videos);
         setWatchList(watchListData.contentData);
        setLoading(false);

    }, []);

    useEffect(() => {
        loadData().then(undefined);
    }, []);

    const calculateRankingList = useCallback(() => {

        if(watchList === null || videos === null){
            return [];
        }

        const data = watchList.map((user) => {
            let requiredVideos = user.department_departmentTousers.video_departments.length;
            let watched = 0;
            let oldWatched = 0;
            console.log(requiredVideos);
            videos.forEach((videoReq) => {
                if(user.department_departmentTousers.video_departments.findIndex((userReq) => userReq.video_id === videoReq.id) === -1){
                    requiredVideos += 1;
                }
            })


           user.seen_videos.forEach((seen) => {
               if(user.department_departmentTousers.video_departments.findIndex((requried) => requried.video_id === seen.video_id) !== -1){
                   if(moment(seen.seen_at).isAfter(moment().subtract(1, "year"))){
                       oldWatched += 1;
                       watched += 1;
                   }else{

                       watched += 1;
                   }
               }else if(videos.findIndex((allRequired) => allRequired.id===seen.video_id) !== -1){
                   if(moment(seen.seen_at).isAfter(moment().subtract(1, "year"))){
                       watched += 1;
                       oldWatched += 1;
                   }else{
                       watched += 1;
                   }
               }
           })

            return {
               ...user,
                watched,
                oldWatched,
                requiredVideos
            }

        });

        return data;

    }, [watchList, videos]);


    const getColor = useCallback((percent: number) => {

        if(percent >= 75){
            return 'green';
        }else if(percent >= 50) {
            return 'lightGreen'
        }else if(percent >= 25){
            return 'orange';
        }else if(percent >= 10){
            return 'lightOrange';
        }else if(isNaN(percent)){
            return ''
        } else {
            return 'red'
        }


    }, []);


    const contentColumns = useCallback(() => {

        return [
            {
                field: 'showId',
                name: I18N("ID", language),
                sortable: true,
                filterable: false,
                hidden: false,
                disableColumnMenu: true,
                renderCell: (params: any) => {
                    console.log(params.row);
                    if(params.row.notifications.length > 0){
                        if(showUserNames){
                            if(!moment(params.row.archived_at).isBefore(moment(new Date()).subtract(1, "year").add("day", 1))){
                                return <span style={{fontWeight: 'bold', display: "flex", flexDirection: "row", alignContent: "center"}}>{params.row.firstName} {params.row.lastName} {params.row.isArchived ? (<img src={archivedIcon} width={20} style={{marginLeft: 10, padding: 3, borderRadius:5, background: "#F86D70"}} />) : ''}</span>;
                            }
                        }
                    }
                    return <span style={{fontWeight: 'bold', display: "flex", flexDirection: "row", alignContent: "center"}}>{params.row.id} {params.row.isArchived ? (<img src={archivedIcon} width={20} style={{marginLeft: 10, padding: 3, borderRadius:5, background: "#F86D70"}} />) : ''}</span>;
                }
            },
            {
                field: 'Videos gesehen',
                sortable: true,
                filterable: false,
                disableColumnMenu: true,
                name: I18N("totalSeen", language),
                valueGetter: (params) => {
                    return params.row.watched
                },
                renderCell: (params: any) => {
                    return (
                        <div className="endFlex">
                            <div className="rankingText">{params.row.watched} / {params.row.requiredVideos} ({(params.row.watched / params.row.requiredVideos * 100).toFixed(0)}%)</div>
                            <div className={`rankingBar ${getColor((params.row.watched / params.row.requiredVideos * 100))}`} style={{width: (params.row.watched / params.row.requiredVideos * 100) + '%'}}>
                            </div>
                        </div>
                    )
                }
            },
            {
                field: 'Videos gesehen letzte 12 Monate',
                name: I18N('totalSeenLast12', language),
                sortable: true,
                filterable: false,
                disableColumnMenu: true,
                width: 250,
                valueGetter: (params) => {
                    return params.row.oldWatched
                },
                renderCell: (params: any) => {
                    return (
                        <div className={"endFlex"}>
                            <div className="rankingText">{params.row.oldWatched} / {params.row.watched} ({isNaN((params.row.oldWatched / params.row.watched * 100)) ? '0' : (params.row.oldWatched / params.row.watched * 100).toFixed(0)}%)</div>
                            <div className={`rankingBar ${getColor((params.row.oldWatched / params.row.watched * 100))}`} style={{width: (params.row.oldWatched / params.row.watched * 100) + '%'}}>
                            </div>
                        </div>
                    )
                }
            },
        ];

    }, [showUserNames]);

    const returnViews = useCallback(() => {

        if(activeView === 'userHome'){
            return (
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div className="endFlex flex-row" style={{marginLeft: "auto", width: "auto", cursor: "pointer", justifyItems: "flex-end", float: "right", fontWeight: "bold", fontSize: 15, lineHeight: 1.8, marginRight:30}} onClick={() => buttonActivated && setShowUserNames(!showUserNames)}>
                            {buttonActivated && (<>{I18N("showUsernames", language)} {showUserNames ? <img src={ShowIcon} width={25} height={25} /> : <img src={HideIcon} width={25} height={25} /> }</>)}
                        </div>
                        <div style={{height: 670}}>
                            <TableMui sortingMode={"client"} filterMode={"client"} onClick={(data) => {
                                setSelectedUser(data.row.id)
                                setActiveView('userIn');
                            }} data={calculateRankingList()} columns={contentColumns()} />
                        </div>
                    </div>

            )
        }else if(activeView === 'userIn'){
            return <UserAnalyticsSingle onBack={() => {
                setActiveView('userHome');
                setSelectedUser(null);
                loadBreadCrumb();
            }} contentID={selectedUser} onClick={() => {}} />
        }

    }, [activeView, calculateRankingList, getColor, language, props, selectedUser, buttonActivated, showUserNames]);

    return (
        <div style={{marginTop: 30}}>
            {/*<FormButton text={I18N("back", language)} onClick={() => {*/}
            {/*    if(activeView === 'userIn'){*/}
            {/*        setActiveView('userHome');*/}
            {/*        setSelectedUser(null);*/}
            {/*    }else if(activeView === 'userHome') {*/}
            {/*        props.backHome();*/}
            {/*    }*/}
            {/*}} isLoading={loading} withLoadingInFullScreen={true} />*/}
            {returnViews()}
        </div>
    )
}
