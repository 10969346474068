import { TextField} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import ViewBox from "../ViewComponents/ViewBox";
import {useBreadCrumb} from "../States/BreadCrumbState";


type Props = {
    editMode?: any
    backCallback: () => void;
    onNameChange: (name: string) => void;
    onFinish: () => void;
}

export function TrophyOperation(props: Props) {

    const [name, setName] = useState<string>('');
    const language = useLanguageState(state => state.language);
    const breadCrumb = useBreadCrumb();

    useEffect(() => {
        if(props.editMode !== null) {
            setName(props.editMode.name)
        }
    }, [props.editMode]);

    useEffect(() => {
        props.onNameChange(name);
    }, [name, props.onNameChange]);

    useEffect(() => {
        breadCrumb.setTitle(props.editMode ? props.editMode.name : I18N('createTrophy', language));
        breadCrumb.setAdditionalCrumb({
            title: props.editMode ? props.editMode.name : I18N('createTrophy', language),
            onClick: undefined
        });
        breadCrumb.setBackButton(props.backCallback);
        breadCrumb.setChildren([
            {
                color: 'lightGreen',
                key: 'learningPlanSaver',
                title: props.editMode ? I18N('updateTrophy', language) : I18N("createTrophy", language),
                onClick: props.onFinish,
                icon: "save",
            }
        ])

    }, [language, props.backCallback, props.editMode, props.onFinish]);

    return (
        <ViewBox>
            <TextField
                fullWidth
                label={I18N('name', language)}
                style={{ marginTop: 20 }}
                value={name}
                onChange={(text) => setName(text.target.value)}
            />
        </ViewBox>
    )
}
