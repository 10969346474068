import React, {useCallback, useEffect, useState} from 'react';
import UserList from "./UserList";
import UserOperation from "./UserOperation";
import { UserType } from "../../Types/UserType";
import { useLanguageState } from "../../States/LanguageState";
import { I18N } from "../../i18n/i18n";
import { UsersRepository } from "../../Repositories/UsersRepository";
import {OptionsType} from "react-select";
import ImportUsers from "./ImportUsers";
import {useBreadCrumb} from "../../States/BreadCrumbState";
import {useLocation, useParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import {PassStringCreator} from "../../Helpers/PassStringCreator";

const userRepository = new UsersRepository();

type Props = {
    style: any;
    isMobile: () => boolean;
}

type State = {
    username: string;
    permission: OptionsType<{ value: string, label: string }> | false;
    firstName: string,
    lastName: string,
    email: string;
    phone: string,
    id: string,
    department: { value: string, label: string } | undefined
    language: { value: string, label: string } | undefined;
    companies: undefined | {value: number, label: string}[]
    password: string
}


export function User(props: Props) {

    //Creating States
    const [createOpen, setCreateOpen] = useState<boolean>(false);
    const [inEditMode, setInEditMode] = useState<null | UserType>(null);
    const language = useLanguageState(state => state.language);
    const [formLoading, setFormLoading] = useState(false);
    const [importOpen, setImportOpen] = useState(false);
    const [showArchive, setShowArchive] = useState(false);
    const [errorFields, setErrorFields] = useState([]);
    const breadCrumb = useBreadCrumb();
    const router = useParams<{id: string | undefined}>();
    const {enqueueSnackbar} = useSnackbar();

    const getUserSingleAndOpen = useCallback(async (userID) => {
        setFormLoading(true);
        await userRepository.getWithID(userID).then((response) => {
            setFormLoading(false);
            setInEditMode(response);
            setCreateOpen(true);
        }).catch((err) => setFormLoading(false));

    }, []);

    useEffect(() => {

        if(router.id !== undefined){

            getUserSingleAndOpen(router.id).then(r => undefined);
        }
    }, []);


    const [formState, setFormState] = useState<State>({
        username: '',
        permission: false,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        language: undefined,
        id: '',
        department: undefined,
        companies: undefined,
        password: ''
    });

    const location = useLocation();
    const [mountKey, setMountKey] = useState(undefined);

    useEffect(() => {
        if(location.key !== undefined){
            if(location.key !== mountKey){
                onReturnBackToView();
            }
        }
        setMountKey(location.key);
    }, [location, mountKey]);

    // Scrolls to top when page changes
    const scrollTop = useCallback(() => {
        window.scrollTo({
            behavior: "smooth",
            top: 0
        });
    }, []);


    // back button function, can be called without button and returns back to table view
    const backToUserTable = useCallback(() => {
        setErrorFields([]);
        setCreateOpen(false);
        setInEditMode(null);
        scrollTop();
    }, [scrollTop]);


    //Creating and updating user
    const saveData = useCallback(async () => {

        setErrorFields([]);

        if (formState.firstName === '' || formState.language === undefined || formState.lastName === '' || formState.department === undefined || formState.email === '' || formState.phone === '' || formState.username === '' || !formState.permission || (inEditMode === null && formState.password === '')) {

            enqueueSnackbar(I18N('pleaseFill', language, [{ key: 'name', value: I18N('user', language) }]), {variant: "error"})
            return;

        }

        setFormLoading(true);

        let editFormState = JSON.parse(JSON.stringify(formState));
        editFormState.department = formState.department.value;
        editFormState.language = formState.language.value;
        editFormState.permission = formState.permission.map((permission) => permission.value);
        editFormState.companies = formState.companies === undefined || formState.companies === null ? [] : formState.companies.map((i) => i.value);
        if (inEditMode !== null) {

            await userRepository.update(editFormState, inEditMode.id).then(async (res) => {
                setFormLoading(false);
                if(res.status){
                    enqueueSnackbar(I18N('successfullyUpdatedReturning', language, [{ key: 'name', value: I18N('user', language) }]), {variant: "success"})
                    onReturnBackToView();
                }else{
                    if(res.regexString) {
                        let buildText = PassStringCreator(res, language);
                        await enqueueSnackbar(buildText, {variant: "error", style: {whiteSpace: 'pre-line'}});
                        return;
                    }else{
                        enqueueSnackbar(I18N('unexpectedError', language), {variant: "error"})
                    }

                }

            }).catch(async (err) => {
                setFormLoading(false);
                console.log(err);
                if(err.code === 422){
                    if(err.data){
                        setErrorFields(err.data);
                        enqueueSnackbar(I18N('fieldError', language, [{key: 'fields', value: err.data.map((field) => field + ' ')}]), {variant: "error"})
                    }
                }else{
                    if(err.data !== undefined){
                        if(err.data.errorMessage !== undefined){
                            enqueueSnackbar(err.data.errorMessage, {variant: "error"})
                            return;
                        }

                    }
                    enqueueSnackbar(I18N('unexpectedError', language), {variant: "error"})

                }
                return;
            });

        } else {
            await userRepository.post(editFormState).then(async (res) => {
                setFormLoading(false);
                if(res.status){
                    enqueueSnackbar( I18N("successfullyCreatedReturning", language, [{key: 'name', value: I18N('user', language)}]), {variant: "success"})
                    onReturnBackToView();

                }else{

                    if(res.regexString) {
                        let buildText = PassStringCreator(res, language);
                        await enqueueSnackbar(buildText, {variant: "error", style: {whiteSpace: 'pre-line'}});
                        return;
                    }else{
                        enqueueSnackbar(I18N('unexpectedError', language), {variant: "error"})
                    }

                }



            }).catch(async (err) => {
                setFormLoading(false);
                console.log(err);
                if(err.code === 422){
                    if(err.data){
                        setErrorFields(err.data);
                        enqueueSnackbar( I18N('fieldError', language, [{key: 'fields', value: err.data.map((field) => field + ' ')}]), {variant: "error"})
                    }
                }else{
                    if(err.data !== undefined){
                        if(err.data.errorMessage !== undefined){

                            enqueueSnackbar( err.data.errorMessage, {variant: "error"})

                            return;
                        }

                    }
                    enqueueSnackbar( I18N('unexpectedError', language), {variant: "error"})

                }
                return;
            });

        }
        setFormLoading(false);

    }, [backToUserTable, formState, inEditMode, language]);



    const onReturnBackToView = useCallback(() => {
        setImportOpen(false);
        breadCrumb.setTitle(I18N('users', language))
        breadCrumb.setCrumb([{title: I18N('configuration', language), onClick: undefined}, {
            title: I18N('users', language),
            onClick: onReturnBackToView
        }]);
        breadCrumb.setBackButton(null);
        breadCrumb.setChildren([
            {
              key: "importUser",
              title: I18N("import", language),
              onClick: () => setImportOpen(true),
              color: 'yellow',
            },
            {
                key: "archiveShow",
                icon: "archive",
                title: I18N(showArchive ? "showNormal" : "showArchived", language),
                onClick: () => setShowArchive(!showArchive),
                color: showArchive ? 'lightGreen' : 'red',
            },
            {
                key: "userCreateNew",
                title: I18N('new', language),
                onClick: () => {
                    setCreateOpen(!createOpen);
                    scrollTop();
                },
                color: 'dark',
                icon: "plus"
            }
        ])
        backToUserTable();
    }, [language, showArchive]);


    useEffect(() => {
        onReturnBackToView()
    }, [onReturnBackToView]);

    return (
        <div style={props.style}>
            {importOpen ? (
                <ImportUsers onFinish={() => {

                    onReturnBackToView();
                }} />
            ) : (
              <React.Fragment>
                  {!createOpen ?
                      <UserList showArchive={showArchive} editMode={(item) => {
                          setInEditMode(item);
                          setCreateOpen(true);
                          scrollTop();
                      }} />
                      : (
                          <UserOperation setErrorFields={setErrorFields} onSave={saveData} errorFields={errorFields} backCallback={onReturnBackToView} editMode={inEditMode} formStateUpdate={setFormState} />
                      )}
              </React.Fragment>
            )}



        </div>
    )

}
