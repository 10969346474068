import {useBreadCrumb} from "../States/BreadCrumbState";
import React from "react";
import {useTheme} from "@mui/material";
import FormButton from "./FormButton";
import BackSVG from "../assets/ack2.svg";

export default function BreadCrumb() {

    const crumb = useBreadCrumb();
    const theme = useTheme();

    return (
        <div>
            <div style={{fontWeight: 600, height: 18, fontSize: 18, width: '100%', display: 'flex', flexDirection: 'column', color: theme.palette.mode === "dark" && "#fff"}}>
                {crumb.title}
                <ul className="breadCrumb" style={{color: theme.palette.mode === "dark" && "#fff"}}>
                    {crumb.backButton && (
                        <div className="smallBackButton yellow" style={{marginRight: 10}} onClick={crumb.backButton}>
                            <img src={BackSVG} width={10}  />
                        </div>
                    )}
                    {crumb.crumbs.map((item, key) => {
                        const isLast = key !== crumb.crumbs.length - 1;
                        return (
                            <>
                                <li onClick={item.onClick}>{item.title}</li>
                                {isLast && (
                                    <li className="divider"/>
                                )}
                            </>
                        );

                    })}
                </ul>
            </div>
            <div>
                {crumb.children !== null && (React.isValidElement(crumb.children) ? (
                    <>{crumb.children}</>
                ): (
                    <div className="flex-row-button">
                        {crumb.children.map((item) => {
                           return (<FormButton withLoadingInFullScreen={item.isFullLoading} icon={item.icon} smallText={item.smallText} key={item.key} color={item.color} text={item.title} onClick={item.onClick} isLoading={item.isLoading} smallButton={item.isSmall} backButton={item.isBackButton} deleteButton={item.isDeleteButton} />)
                        })}
                    </div>
                ))}

            </div>
        </div>

    )


}