import {HeadItem, PermissionCover, PermissionItem, PermissionName} from "../Components/TableComponents";
import {I18N} from "../../i18n/i18n";
import {Swiper, SwiperSlide} from "swiper/react/swiper-react";
import {Chip} from "@mui/material";
import React, {useEffect} from "react";
import {useLanguageState} from "../../States/LanguageState";
import PermissionSearcher from "../Components/PermissionSearcher";
import GroupPermissionSearcher from "../Components/GroupPermissionSearcher";

const avPermissions = ["seeOwn", 'see', 'create', 'edit', 'delete'];

export default function Configuration(props: {groupList: any, setSelectedPermission: any}){
    const language = useLanguageState(state => state.language);

    // useEffect(() => {
    //     props.setSelectedPermission({name: 'companies', permits: {seeOwn: 'ViewAllCompanies', see: "ViewCompanies", create: "CreateCompany", delete: "DeleteCompany", edit: "EditCompany"}});
    // }, []);
    return (
        <>
            <div style={{width: 218}}>
                <PermissionName onClick={() => props.setSelectedPermission({name: 'companies', permits: {seeOwn: 'ViewCompanies', see: "ViewAllCompanies", create: "CreateCompany", delete: "DeleteCompany", edit: "EditCompany"}})} title={I18N("companies", language)} />
                <PermissionName onClick={() => props.setSelectedPermission({name: 'departments', permits: {seeOwn: 'ViewDepartments', see: "ViewAllDepartments", create: "CreateDepartment", delete: "DeleteDepartment", edit: "EditDepartment"}})} title={I18N("departments", language)} />
                <PermissionName onClick={() => props.setSelectedPermission({name: 'users', permits: {seeOwn: 'ViewUser', see: "ViewAllUsers", create: "CreateUser", delete: "DeleteUser", edit: "EditUser"}})} title={I18N("users", language)} />
                <PermissionName onClick={() => props.setSelectedPermission({name: 'permissionMatrix', permits: {seeOwn: '-', see: "ViewPermissionMatrix", create: "-", delete: "-", edit: "EditPermissionMatrix"}})} title={I18N("permissionMatrix", language)} />
                <PermissionName onClick={() => props.setSelectedPermission({name: 'categories', permits: {seeOwn: 'ViewCategories', see: "ViewAllCategories", create: "CreateCategory", delete: "DeleteCategory", edit: "EditCategory"}})} title={I18N("categories", language)} />
                <PermissionName onClick={() => props.setSelectedPermission({name: 'learnContent', permits: {seeOwn: 'ViewContent', see: "ViewAllContent", create: "CreateContent", delete: "DeleteContent", edit: "EditContent"}})} title={I18N("learnContent", language)} />
                <PermissionName onClick={() => props.setSelectedPermission({name:'learningPlan', permits: {seeOwn: 'ViewLearningPlan', see: "ViewAllLearningPlan", create: "CreateLearningPlan", delete: "DeleteLearningPlan", edit: "EditLearningPlan"}})} title={I18N("learningPlan", language)} />
                <PermissionName onClick={() => props.setSelectedPermission({name: 'trophies', permits:{seeOwn: 'ViewTrophies', see: "ViewAllTrophies", create: "CreateTrophy", delete: "DeleteTrophy", edit: "EditTrophy"}})} title={I18N("trophies", language)} />
                <PermissionName onClick={() => props.setSelectedPermission({name: 'lists', permits:{seeOwn: 'ViewList', see: "ViewAllList", create: "CreateLists", delete: "DeleteList", edit: "EditList"}})} title={I18N("lists", language)} />
                <PermissionName onClick={() => props.setSelectedPermission({name: 'quizEditor', permits: {seeOwn: 'ViewQuiz', see: "ViewAllQuiz", create: "CreateQuiz", delete: "DeleteQuiz", edit: "EditQuiz"}})} title={I18N("quizEditor", language)} />
                <PermissionName onClick={() => props.setSelectedPermission({name: 'passwordTexts', permits: {seeOwn: '-', see: "-", create: "-", delete: "-", edit: "EditPasswordTexts"}})} title={I18N("passwordTexts", language)} />
                <PermissionName onClick={() => props.setSelectedPermission({name: 'passwordqquality', permits: {seeOwn: '-', see: "-", create: "-", delete: "-", edit: "EditPasswordQuality"}})} title={I18N("passwordqquality", language)} />
                <PermissionName onClick={() => props.setSelectedPermission({name: 'notification', permits: {seeOwn: '-', see: "-", create: "CreateNotification", delete: "-", edit: "-"}})} title={I18N("notification", language)} />
                <PermissionName onClick={() => props.setSelectedPermission({name: 'api_keys', permits: {seeOwn: '-', see: "ViewApiKeys", create: "CreateApiKeys", delete: "DeleteApiKeys", edit: "EditApiKeys"}})} title={I18N("api_keys", language)} />
                <PermissionName onClick={() => props.setSelectedPermission({name: "analytics", permits: {seeOwn: '-', see: "-", create: "-", delete: "-", edit: "EditAnalyticsSettings"}})} title={I18N("analytics", language)} />
            </div>
            <Swiper style={{width: '100%'}} freeMode slidesPerView="auto" spaceBetween={0}>

                {avPermissions.map((i) => {
                   return (
                       <SwiperSlide style={{width: 170}}>
                           <PermissionItem key={"Companies"}>
                               <GroupPermissionSearcher permissionList={{seeOwn: 'ViewCompanies', see: "ViewAllCompanies", create: "CreateCompany", delete: "DeleteCompany", edit: "EditCompany"}} groups={props.groupList} permissionToLook={i} />
                           </PermissionItem>
                           <PermissionItem key={"Departments"}>
                               <GroupPermissionSearcher groups={props.groupList} permissionList={{seeOwn: 'ViewDepartments', see: "ViewAllDepartments", create: "CreateDepartment", delete: "DeleteDepartment", edit: "EditDepartment"}} permissionToLook={i} />
                           </PermissionItem>
                           <PermissionItem key={"Users"}>
                               <GroupPermissionSearcher groups={props.groupList} permissionList={{seeOwn: 'ViewUser', see: "ViewAllUsers", create: "CreateUser", delete: "DeleteUser", edit: "EditUser"}} permissionToLook={i} />
                           </PermissionItem>
                           <PermissionItem key={"permissionmatrix"}>
                               <GroupPermissionSearcher groups={props.groupList} permissionList={{seeOwn: '-', see: "ViewPermissionMatrix", create: "-", delete: "-", edit: "EditPermissionMatrix"}} permissionToLook={i} />

                           </PermissionItem>
                           <PermissionItem key={"categories"}>
                               <GroupPermissionSearcher groups={props.groupList} permissionList={{seeOwn: 'ViewCategories', see: "ViewAllCategories", create: "CreateCategory", delete: "DeleteCategory", edit: "EditCategory"}} permissionToLook={i} />
                           </PermissionItem>
                           <PermissionItem key={"learnContent"}>
                               <GroupPermissionSearcher groups={props.groupList} permissionList={{seeOwn: 'ViewContent', see: "ViewAllContent", create: "CreateContent", delete: "DeleteContent", edit: "EditContent"}} permissionToLook={i} />
                           </PermissionItem>
                           <PermissionItem key={"learningPlan"}>
                               <GroupPermissionSearcher groups={props.groupList} permissionList={{seeOwn: 'ViewLearningPlan', see: "ViewAllLearningPlan", create: "CreateLearningPlan", delete: "DeleteLearningPlan", edit: "EditLearningPlan"}} permissionToLook={i} />

                           </PermissionItem>
                           <PermissionItem key={"trophies"}>
                               <GroupPermissionSearcher groups={props.groupList} permissionList={{seeOwn: 'ViewTrophies', see: "ViewAllTrophies", create: "CreateTrophy", delete: "DeleteTrophy", edit: "EditTrophy"}} permissionToLook={i} />
                           </PermissionItem>
                           <PermissionItem key={"lists"}>
                               <GroupPermissionSearcher groups={props.groupList} permissionList={{seeOwn: 'ViewList', see: "ViewAllList", create: "CreateLists", delete: "DeleteList", edit: "EditList"}} permissionToLook={i} />
                           </PermissionItem>
                           <PermissionItem key={"quizEditor"}>
                               <GroupPermissionSearcher groups={props.groupList} permissionList={{seeOwn: 'ViewQuiz', see: "ViewAllQuiz", create: "CreateQuiz", delete: "DeleteQuiz", edit: "EditQuiz"}} permissionToLook={i} />
                           </PermissionItem>
                           <PermissionItem key={"passwordTexts"}>
                               <GroupPermissionSearcher groups={props.groupList} permissionList={{seeOwn: '-', see: "-", create: "-", delete: "-", edit: "EditPasswordTexts"}} permissionToLook={i} />
                           </PermissionItem>
                           <PermissionItem key={"passwordqquality"}>
                               <GroupPermissionSearcher groups={props.groupList} permissionList={{seeOwn: '-', see: "-", create: "-", delete: "-", edit: "EditPasswordQuality"}} permissionToLook={i} />
                           </PermissionItem>
                           <PermissionItem key={"notification"}>
                               <GroupPermissionSearcher groups={props.groupList} permissionList={{seeOwn: '-', see: "-", create: "CreateNotification", delete: "-", edit: "-"}} permissionToLook={i} />
                           </PermissionItem>
                           <PermissionItem key={"api_keys"}>
                               <GroupPermissionSearcher groups={props.groupList} permissionList={{seeOwn: '-', see: "ViewApiKeys", create: "CreateApiKeys", delete: "DeleteApiKeys", edit: "EditApiKeys"}} permissionToLook={i} />
                           </PermissionItem>
                           <PermissionItem key={"analytics"}>
                               <GroupPermissionSearcher groups={props.groupList} permissionList={{seeOwn: '-', see: "-", create: "-", delete: "-", edit: "EditAnalyticsSettings"}} permissionToLook={i} />
                           </PermissionItem>
                       </SwiperSlide>
                   )
                })}
            </Swiper>
        </>
    )

}

