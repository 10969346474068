import React, {useCallback, useEffect, useState} from "react";
import {FormControlLabel, IconButton, InputAdornment, Switch, TextField, useTheme} from "@mui/material";
import {UsersRepository} from "../../Repositories/UsersRepository";
import {UserGroupsType, UserType} from "../../Types/UserType";
import Select from "react-select/creatable";
import NotCreatable, {OptionsType} from "react-select";
import {DepartmentsRepository} from "../../Repositories/DepartmentsRepository";
import {LanguageRepository} from "../../Repositories/LanguageRepository";
import {I18N} from "../../i18n/i18n";
import {useLanguageState} from "../../States/LanguageState";
import ViewBox from "../../ViewComponents/ViewBox";
import {Icon} from "@iconify/react";
import eyeFill from "@iconify-icons/eva/eye-fill";
import randomIcon from "@iconify-icons/eva/shuffle-fill";
import eyeOffFill from "@iconify-icons/eva/eye-off-fill";
import {DepartmentType} from "../../Types/DepartmentTypes";
import {colorForSelect} from "../../ComplinessTheme";
import {styled} from "@mui/material/styles";
import {CompanyRepository} from "../../Repositories/CompanyRepository";
import {useLoginState} from "../../States/UserState";
import {useBreadCrumb} from "../../States/BreadCrumbState";
import FormButton from "../../ViewComponents/FormButton";
import SelectAutocomplete from "../../ViewComponents/SelectAutoComplete";

const userRepository = new UsersRepository();
const departmentRepository = new DepartmentsRepository();
const languageRepository = new LanguageRepository();
const companyRepository = new CompanyRepository();

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

type Props = {
    editMode: null | UserType,
    backCallback: () => void,
    formStateUpdate: (formState) => void,
    errorFields: string[],
    setErrorFields: (data: string[]) => void;
    onSave: () => void;
}

type State = {
    username: string;
    permission: OptionsType<{ value: string, label: string }> | undefined;
    firstName: string,
    lastName: string,
    email: string;
    phone: string,
    id: string,
    department: {value: number, label: string} | undefined
    language: {value: string, label: string} | undefined
    password: string
    sendEmail: boolean;
    companies: undefined | {value: number, label : string}[]
}

export default function UserOperation(props: Props){

    //Creating states
    const [formState, setFormState] = useState<State>({
        username: '',
        permission: undefined,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        language: undefined,
        id: '',
        department: undefined,
        password: '',
        sendEmail: true,
        companies: undefined
    });
    const theme = useTheme();
    const breadCrumb = useBreadCrumb();
    const [showPassword, setShowPassword] = useState(false);
    const [departments, setDepartments] = useState<DepartmentType[]>([]);
    const [languages, setLanguages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const language = useLanguageState(state => state.language)
    const [loadedData, setLoadedData] = useState(false);
    const [groups, setGroups] = useState<UserGroupsType[]>([]);
    const [userGroup, setUserGroup] = useState(null);
    const [companies, setCompanies] = useState([]);
    const authService = useLoginState();


    //Setting states when edit mode called
    useEffect(() => {
        let userDep = undefined;
        let userLanguage = undefined;
        let userG = undefined;
        if(props.editMode !== null && !loadedData && props.editMode !== undefined){
            const dep = departments.find((item) => item.ID === props.editMode.innerData.department);
            const language = languages.find((item) => item.code === props.editMode.innerData.language);
            if(dep !== undefined){
                userDep = {label: dep.name, value: dep.ID}
            }
            if(language !== undefined){
                userLanguage = {label: language.name, value: language.code}
            }
            if(userGroup !== null){
                userG = userGroup.map((uG) => {
                    return {label: uG.name, value: uG.id}
                });
            }

            if(userDep !== undefined && userLanguage !== undefined && userG !== undefined){
                setLoadedData(true);
            }

            const complist = props.editMode.innerData.users_companies.map((i) => {
                return {
                    value: i.company.id,
                    label: i.company.name
                }
            })

            setFormState({
                ...formState,
                username: props.editMode.username,
                permission: userG,
                firstName: props.editMode.firstName,
                lastName: props.editMode.lastName,
                email: props.editMode.innerData.emailAddress,
                phone: props.editMode.innerData.phone,
                language: userLanguage,
                id: props.editMode.id,
                department: userDep,
                companies: complist
            })
        }


    }, [props.editMode, languages, departments, loadedData, userGroup])

    //Load departments
    const loadDepartments = useCallback(async  () => {

        const depList = await departmentRepository.getAll();
        setDepartments(depList as DepartmentType[]);
    }, []);

    //Loading languages
    const loadLanguages = useCallback(async  () => {

        const languages = await languageRepository.getAll();
        setLanguages(languages);

    }, []);

    //Loading groups
    const loadGroups = useCallback( async () => {
        if(props.editMode !== null){
            const uGroup = await userRepository.getUserGroup(props.editMode.id);
            setUserGroup(uGroup);
        }
        let groupList = await userRepository.getGroups();
        const isUserSuper = authService.data.innerData.group.filter((item) => {
            return item.name === "Superadmin"
        }).length;
        groupList = groupList.filter((i) => {
            if(i.name === "Superadmin"){
                if(isUserSuper > 0){
                    return true;
                }else{
                    return false;
                }
            }else{
                return true;
            }
        })

        setGroups(groupList);

    }, [props.editMode]);

    const loadCompanies = useCallback( async () => {

        const subList = await companyRepository.getSubCompanies();
        const userComp = subList;
        setCompanies(userComp);
    }, [authService]);

    //Calling all load functions when page loads.
    useEffect(() => {


        Promise.all([
            loadDepartments(),
            loadLanguages(),
            loadGroups(),
            loadCompanies()
        ]).then(undefined);

    }, [loadDepartments, loadGroups, loadLanguages]);



    //Inline creation of department
    const createNewDepartment = useCallback(async (departmentName: string) => {

        setIsLoading(true);

        const create = await departmentRepository.post({name: departmentName});

        setDepartments([...departments, create])
        setFormState({...formState, department: {value: create.ID, label: create.name}});

        setIsLoading(false);

    }, [departments, formState]);

    //Generating random password function
    const generateRandomPassword = useCallback(() => {

        setFormState({...formState, password: Math.random().toString(36).slice(2)});
        setShowPassword(true);

    }, [formState]);



    //Calling upper function when something changes in the form
    useEffect(() => {
        props.formStateUpdate(formState);
    }, [formState, props]);

    useEffect(() => {
        breadCrumb.setAdditionalCrumb({
            title: props.editMode ? props.editMode.username : I18N('createUser', language),
            onClick: undefined
        });
        breadCrumb.setTitle(props.editMode ? props.editMode.username : I18N('createUser', language));
        breadCrumb.setBackButton(props.backCallback)
        breadCrumb.setChildren([
            {key: "userInnerCreate", title: props.editMode ? I18N('updateUser', language) : I18N('createUser', language), onClick: async () => {
                setIsLoading(true);
                await props.onSave()
                setIsLoading(false);
                }, color: "lightGreen", icon: "save"},
        ])

    }, [props.onSave])

    return (
        <>

        <div className="flex-row">

            <ViewBox title={I18N('personalInformationUser', language)}>
                <TextField
                    fullWidth
                    label={I18N('username', language)}
                    style={{ marginTop: 20 }}
                    value={formState.username}
                    error={props.errorFields.findIndex((err) => err === 'username') !== -1}
                    onChange={(text) => setFormState({...formState,  username: text.target.value })}
                />

                {!props.editMode && (<TextField
                    fullWidth
                    label={I18N('password', language)}
                    style={{ marginTop: 20 }}
                    value={formState.password}
                    error={props.errorFields.findIndex((err) => err === 'password') !== -1}
                    onChange={(text) => setFormState({ ...formState, password: text.target.value })}
                    type={showPassword ? 'text' : 'password'}
                    // error={isPasswordValid()}
                    InputProps={{
                        endAdornment: (
                            <React.Fragment>
                                <InputAdornment position={'end'}>
                                    <IconButton onClick={generateRandomPassword} edge="end">
                                        <Icon icon={randomIcon} />
                                    </IconButton>
                                </InputAdornment>
                                <InputAdornment position={'end'}>
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                    </IconButton>
                                </InputAdornment>
                            </React.Fragment>
                        )
                    }}
                />
                )}

                <div style={{width: '100%', marginTop: 5}}>

                    <SelectAutocomplete label={I18N("permission", language)} multiple={true} maxTags={2} options={groups.map((comp) => { return {value: comp.id, label: comp.name}})} value={formState.permission} onChange={(data) => setFormState({...formState, permission: data})} />


                    {/*<NotCreatable styles={{*/}
                    {/*    valueContainer: (styles) => {*/}
                    {/*        return {*/}
                    {/*            ...styles,*/}
                    {/*            height: 50,*/}
                    {/*            scrollbarWidth: "none",*/}

                    {/*            msOverflowStyle: "none",*/}
                    {/*            overflowX: "hidden",*/}
                    {/*            overflowY: "scroll"*/}
                    {/*        };*/}
                    {/*    },*/}
                    {/*}} theme={(template) => {*/}
                    {/*    return {*/}
                    {/*        ...template,*/}
                    {/*        colors: {*/}
                    {/*            ...template.colors,*/}
                    {/*            ...colorForSelect(theme)*/}
                    {/*        }*/}
                    {/*    }*/}
                    {/*}} placeholder={I18N('permission', language)} options={groups.map((dep) => {*/}
                    {/*    return {value: dep.id, label: dep.name}*/}
                    {/*})} isClearable={true} isMulti={true} value={formState.permission} onChange={(item) => setFormState({...formState, permission: item})} />*/}
                </div>

                <div style={{width: '100%', marginTop: 5}}>
                    <Select theme={(template) => {
                        return {
                            ...template,
                            colors: {
                                ...template.colors,
                                ...colorForSelect(theme)
                            }
                        }
                    }} isClearable={true} isMulti={false} onChange={(change) => {
                        setFormState({...formState, department: change})
                    }}  value={formState.department} isLoading={isLoading} onCreateOption={createNewDepartment} placeholder={"Select Department"} options={departments.map((department) =>  ({value: department.ID, label: department.name}))} />
                </div>

                <div style={{width: '100%', marginTop: 20}}>
                    <NotCreatable theme={(template) => {
                        return {
                            ...template,
                            colors: {
                                ...template.colors,
                                ...colorForSelect(theme)
                            }
                        }
                    }} placeholder={I18N('language', language)} options={languages.map((language) => {
                        return {value: language.code, label: language.name}
                    })} isClearable={true} isMulti={false} value={formState.language} onChange={(item) => setFormState({...formState, language: item})} />
                </div>


                <div style={{width: '100%', marginTop: 5}}>


                    <SelectAutocomplete label={I18N("companies", language)} multiple={true} maxTags={2} options={companies.map((comp) => { return {value: comp.id, label: comp.name}})} value={formState.companies} onChange={(data) => setFormState({...formState, companies: data})} />

                {/*    <Select styles={{*/}
                {/*    valueContainer: (styles) => {*/}
                {/*        return {*/}
                {/*            ...styles,*/}
                {/*            height: 50,*/}
                {/*            scrollbarWidth: "none",*/}

                {/*            msOverflowStyle: "none",*/}
                {/*            overflowX: "hidden",*/}
                {/*            overflowY: "scroll"*/}
                {/*        };*/}
                {/*    },*/}
                {/*}} theme={(template) => {*/}
                {/*    return {*/}
                {/*        ...template,*/}
                {/*        colors: {*/}
                {/*            ...template.colors,*/}
                {/*            ...colorForSelect(theme)*/}
                {/*        }*/}
                {/*    }*/}
                {/*}} isMulti={true} placeholder={I18N('companies', language)} key="subCompanySelect" options={companies.map((comp) => { return {value: comp.id, label: comp.name}})} value={formState.companies} onChange={(data, ) => setFormState({...formState, companies: data as any})} />*/}
                </div>
            </ViewBox>
        <ViewBox title={I18N('personalInformationPerson', language)}>
            <TextField
                fullWidth
                label={I18N('firstName', language)}
                style={{ marginTop: 20 }}
                value={formState.firstName}
                error={props.errorFields.findIndex((err) => err === 'firstname') !== -1}
                onChange={(text) => setFormState({...formState, firstName: text.target.value })}
            />
            <TextField
                fullWidth
                label={I18N('lastName', language)}
                style={{ marginTop: 20 }}
                value={formState.lastName}
                error={props.errorFields.findIndex((err) => err === 'lastname') !== -1}
                onChange={(text) => setFormState({ ...formState, lastName: text.target.value })}
            />
            <div style={{display: "flex", justifyContent: "space-around"}}>
                <TextField
                    fullWidth
                    label={I18N('emailAddress', language)}
                    style={{ marginTop: 20 }}
                    value={formState.email}
                    error={props.errorFields.findIndex((err) => err === 'email') !== -1}
                    onChange={(text) => setFormState({...formState, email: text.target.value })}
                />

                <FormControlLabel
                    value="top"
                    style={{width: 350}}
                    control={<Android12Switch checked={formState.sendEmail} onChange={(e, status) => {
                        setFormState({...formState, sendEmail: status})
                    }} placeholder={"Mail"} />}
                    label={I18N('sendWelcomeEmail', language)}
                    labelPlacement="top"
                />


            </div>

            <TextField
                fullWidth
                label={I18N('phoneNumber', language)}
                style={{ marginTop: 20 }}
                value={formState.phone}
                error={props.errorFields.findIndex((err) => err === 'phone') !== -1}
                onChange={(text) => setFormState({...formState, phone: text.target.value })}
            />


        </ViewBox>

        </div>
            <FormButton text={''} color={"transparent"} onClick={() => {}} withLoadingInFullScreen={true} isLoading={isLoading} />

        </>
    )

}
