import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { VideoRepository } from '../Repositories/VideoRepository';
import { I18N } from '../i18n/i18n';
import { useLanguageState } from '../States/LanguageState';
import { useTableStates } from '../States/TableStates';
import TableMui from '../ViewComponents/TableMui';
import DeleteIcon from '../ViewComponents/DeleteIcon';
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import tableHeightCalculator from '../Helpers/TableHeightCalculator';
import { useSnackbar } from 'notistack';
import languageExporter from '../Helpers/LanguageExporter';
import { UsersRepository } from '../Repositories/UsersRepository';
import { Alert } from '@mui/material';

const videoRepository = new VideoRepository();
const userRepository = new UsersRepository();

const tableName = 'videos';

export default function VideoList(props: { editMode: (item) => void }) {
  const [videos, setVideos] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const language = useLanguageState((state) => state.language);
  const [filter, setFilter] = useState(null);
  const [userData, setUserData] = useState(null);
  const tableStates = useTableStates();
  const [page, setPage] = useState(
    tableStates.table[tableName] !== undefined
      ? tableStates.table[tableName].page
      : 1
  );
  const [limit, setLimit] = useState(
    tableStates.table[tableName] !== undefined
      ? tableStates.table[tableName].limit
      : 10
  );
  const [orderType, setOrderType] = useState(null);
  const [waitingForPage, setWaitingForPage] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const userId = userData?.sub;

  useEffect(() => {
    tableStates.table[tableName] = {
      limit: limit,
      page: page
    };
    tableStates.setTables({ ...tableStates.table });
  }, [limit, page]);

  const getVideoList = useCallback(async () => {
    if (!waitingForPage) {
      setLoading(true);
      const videoList = await videoRepository.getAll({
        page: page,
        limit: limit,
        orderType: orderType,
        filter: filter
      });
      setVideos(videoList);
      setLoading(false);
    }
  }, [page, limit, orderType, filter, waitingForPage]);

  const deleteVideo = useCallback(
    async (video: any) => {
      const del = await videoRepository.delete(video.id);

      if (del instanceof Error) {
        enqueueSnackbar(I18N('unexpectedError', language), {
          variant: 'error'
        });
      } else {
        enqueueSnackbar(
          I18N('deleteSuccess', language, [
            { key: 'name', value: I18N('video', language) }
          ]),
          { variant: 'success' }
        );
        await getVideoList();
      }
    },
    [getVideoList]
  );

  useEffect(() => {
    getVideoList().then(undefined);
    userRepository.getUserInfo().then((response) => {
      setUserData(response);
    });
  }, [getVideoList]);

  const onSortModelChange = useCallback((mode: GridSortModel) => {
    if (mode.length > 0) {
      setOrderType({
        column: mode[0].field,
        type: mode[0].sort
      });
    } else {
      setOrderType(null);
    }
  }, []);

  const onFilterModeChange = useCallback((mode: GridFilterModel) => {
    if (mode.items.length > 0) {
      setFilter({
        column: mode.items[0].columnField,
        value: mode.items[0].value,
        operator: mode.items[0].operatorValue
      });
    } else {
      setFilter(null);
    }
  }, []);

  const tableColumnsMui = useMemo(() => {
    return [
      {
        field: 'id',
        name: 'ID',
        width: 100,
        renderCell: (params: any) => {
          const { value } = params;
          return <span style={{ fontWeight: 'bold' }}>{value}</span>;
        }
      },
      {
        field: 'title',
        name: I18N('title', language),
        renderCell: (params: any) => {
          const { value } = params;

          const item = languageExporter(
            params.row,
            language,
            'video_titles',
            'title',
            'title'
          );
          return item;
        }
      },
      //new Date(contentData.enrolled_at).toLocaleDateString())
      {
        field: 'lastEnrolledAt',
        name: I18N('lastEnrolledAt', language),
        width: 100,
        renderCell: (params: any) => {
          const { value } = params;
          return params.row.enrolled_at === null
            ? '-'
            : new Date(params.row.enrolled_at).toLocaleDateString();
        }
      },
      {
        field: 'trophy',
        width: 150,
        hidden: true,
        sortable: false,
        name: I18N('trophy', language),
        renderCell: (params: any) => {
          const { row } = params;
          return row.trophies.name;
        }
      },
      {
        field: 'departments',
        width: 150,
        sortable: false,
        filterable: false,
        name: I18N('departments', language),
        renderCell: (params: any) => {
          const { row } = params;
          if (row.visibleForAll) {
            return <b>{I18N('visibleForAll', language)}</b>;
          }
          return row.video_departments
            .map((deps) => deps.department.name)
            .join(', ');
        }
      },
      {
        field: 'type',
        width: 10,
        name: I18N('type', language),
        renderCell: (params: any) => {
          const { value } = params;
          switch (value) {
            case 1: {
              return 'Scorm';
            }
            case 2: {
              return 'PDF';
            }
            case 3: {
              return 'MP4';
            }
            case 4: {
              return 'Quiz';
            }
          }
        }
      },
      {
        field: 'accountable',
        width: 150,
        name: I18N('responsibleUser', language),
        renderCell: (params: any) => {
          const { value } = params;
          if (value) {
            return `${value.firstName} ${value.lastName}`;
          }
          return '-';
        }
      },
      {
        field: 'execution_responsible',
        width: 150,
        name: I18N('implementationUser', language),
        renderCell: (params: any) => {
          const { value } = params;
          if (value) {
            return `${value.firstName} ${value.lastName}`;
          }
          return '-';
        }
      },
      {
        field: 'actions',
        width: 100,
        disableReorder: true,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        name: I18N('actions', language),
        renderCell: (params: any) => {
          const {
            row: { accountable }
          } = params;

          const accountableId = accountable?.id;

          let canDelete = false;

          if (accountableId) {
            if (accountableId === userId) {
              canDelete = true;
            }
          } else {
            canDelete = true;
          }

          return canDelete ? (
            <DeleteIcon row={params.row} callBack={deleteVideo} ask={true} />
          ) : (
            <></>
          );
        }
      }
    ];
  }, [language, deleteVideo]);

  return (
    <>
      <Alert severity="info">
        {I18N('responsibleImplementationExplanation', language)}
      </Alert>
      <div style={{ height: tableHeightCalculator(limit) }}>
        <TableMui
          tableName="contentListTable"
          setWaitingForPage={setWaitingForPage}
          page={page}
          onFilterModeChange={onFilterModeChange}
          onPageSizeChange={(size) => setLimit(size)}
          onPageChange={(page) => setPage(page + 1)}
          data={videos.data !== undefined ? videos.data : []}
          onClick={(data, event) => {
            //THIS HOLD NORMAL CLICK EVENT OF THE COLUMN WHICH WE CAN EXTRACT THE FIELD ID AND UNDERSTAND THAT IF ITS CLICK ON THE ACTIONS FIELD,
            // IT CAPTURES THE ITEM THAT IS CLICKED
            // IN ORDER TO KEEP THE SAME BEHAVIOUR WHEN WE CLICK THE OUTSIDE OF THE ROW WE SHOULD ALSO INCLUDE DATA-FIELD="ACTIONS" TO BUTTONS OR THE
            // THINGS THAT WE DONT WANT TO REDIRECT
            if (event.target.dataset.field !== 'actions') {
              const {
                row: { accountable, execution_responsible }
              } = data;

              const accountableId = accountable?.id;
              const executionResponsibleId = execution_responsible?.id;

              let canRead = false;
              let canEdit = false;
              let canEnroll = false;

              if (accountableId || executionResponsibleId) {
                if (
                  accountableId === userId ||
                  executionResponsibleId === userId
                ) {
                  canRead = true;
                }
              } else {
                canRead = true;
              }

              if (accountableId) {
                if (accountableId === userId) {
                  console.log('can edit');
                  canEdit = true;
                  canEnroll = true;
                }
              } else {
                console.log('can edit');
                canEdit = true;
                canEnroll = true;
              }

              if (executionResponsibleId) {
                if (executionResponsibleId === userId) {
                  canEnroll = true;
                }
              } else {
                canEnroll = true;
              }

              if (canRead) {
                props.editMode({ ...data.row, canEdit, canEnroll });
              }
            }
          }}
          onSortModelChange={onSortModelChange}
          isLoading={loading}
          columns={tableColumnsMui}
          pageSize={limit}
          rowCount={videos.total}
        />
      </div>
    </>
  );
}
