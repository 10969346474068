export default function MultiPermissionCheck(permissionList: any[], checkPermission: string[]){

    let status = false;


    permissionList.forEach((i) => {

        if(checkPermission.includes(i)){
            status = true;
        }

    });

    return status;



}