import {
  Backdrop,
  Checkbox,
  CircularProgress,
  TextField,
  useTheme
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { DepartmentsRepository } from '../Repositories/DepartmentsRepository';
import Select from 'react-select/creatable';
import { default as NoCreateSelect } from 'react-select';
import { TrophiesRepository } from '../Repositories/TrophiesRepository';
import { VideoRepository } from '../Repositories/VideoRepository';
import { I18N } from '../i18n/i18n';
import { useLanguageState } from '../States/LanguageState';
import { CategoriesRepository } from '../Repositories/CategoriesRepository';
import ViewBox from '../ViewComponents/ViewBox';
import ViewBoxTab from '../ViewComponents/ViewBoxTab';
import { ContentTypeState } from './VideoPage';
import { VideoUploadRepository } from '../Repositories/VideoUploadRepository';
import { VideoUserUploadRepository } from '../Repositories/VideoUserUploadRepository';
import { LearnVideosType } from '../Types/LearnVideosTypes';
import { CategoryType } from '../Types/CategoryType';
import { TrophiesTypes } from '../Types/TrophiesTypes';
import { DepartmentType } from '../Types/DepartmentTypes';
import { QuizRepository } from '../Repositories/QuizRepository';
import { colorForSelect } from '../ComplinessTheme';
import { CompanyRepository } from '../Repositories/CompanyRepository';
import { useBreadCrumb } from '../States/BreadCrumbState';
import { Android12Switch } from '../ViewComponents/VideoEditComponents/Android12Switch';
import { standartColors } from '../assets/colors/Colors';
import UploadFilesContent from '../ViewComponents/UploadFilesContent';
import LTextField from '../ViewComponents/LTextField';
import { useLoginState } from '../States/UserState';
import SelectAutocomplete from '../ViewComponents/SelectAutoComplete';
import { UserType } from '../Types/UserType';
import { UsersRepository } from '../Repositories/UsersRepository';
const departmentsRepository = new DepartmentsRepository();
const trophyRepository = new TrophiesRepository();
const videoRepository = new VideoRepository();
const videoUploadRepository = new VideoUploadRepository();
const categoriesRepository = new CategoriesRepository();
const videoUserUploadRepository = new VideoUserUploadRepository();
const quizRepository = new QuizRepository();
const companyRepository = new CompanyRepository();
const usersRepository = new UsersRepository();

type Props = {
  isMobile: () => boolean;
  editMode?: any;
  backCallback: () => void;
  onFormStateChange: (formState: ContentTypeState, titleFields: any) => void;
  onFinish: () => void;
  onEnroll: (depsSelected: DepartmentType[], sendNotification: boolean) => void;
  hideSidebar: (status: boolean) => void;
};

export function VideoInfo(props: Props) {
  const [formState, setFormState] = useState<ContentTypeState>({
    title: '',
    url: '',
    departments: [],
    trophies: [],
    trophy: undefined,
    imageURL: '',
    categories: undefined,
    content: undefined,
    visible: true,
    visibleForAll: false,
    type: 1,
    requiredForAll: false,
    quiz: undefined,
    standalone: undefined,
    companies: undefined,
    mandatoryDepartments: [],
    accountable: undefined,
    execution_responsible: undefined
  });
  const [contentList, setContentList] = useState([]);
  const [departments, setDepartments] = useState<DepartmentType[]>([]);
  const [tropies, setTrophies] = useState<TrophiesTypes[]>([]);
  const [quizList, setQuizList] = useState([]);
  const [quizStandalone, setQuizStandalone] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const language = useLanguageState((state) => state.language);
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [users, setUsers] = useState<UserType[]>([]);
  const theme = useTheme();
  const [companies, setCompanies] = useState([]);
  const breadCrumb = useBreadCrumb();
  const [titleFields, setTitleFields] = useState(null);
  const authService = useLoginState();

  const loadCompanies = useCallback(async () => {
    const subList = await companyRepository.getSubCompanies();
    const userComp = subList;
    setCompanies(userComp);
  }, [authService]);

  const getDepartments = useCallback(async () => {
    const depList = await departmentsRepository.getAll();

    setDepartments(depList as DepartmentType[]);
  }, []);

  // useEffect(() => {
  //     props.hideSidebar(true);
  //
  //     return () => {
  //         props.hideSidebar(false);
  //     }
  //
  // }, [])

  const getQuizList = useCallback(async () => {
    const list = await quizRepository.getAll();
    //@ts-ignore
    setQuizList(list.filter((quiz) => quiz.status));
    //@ts-ignore
    setQuizStandalone(list.filter((quiz) => quiz.status));
  }, []);

  const getTrophies = useCallback(async () => {
    const trophyList = await trophyRepository.getAll();

    setTrophies(trophyList as TrophiesTypes[]);
  }, []);

  const getContentList = useCallback(async () => {
    let getList = await videoUploadRepository.getContentList();
    let videoList = (await videoRepository.getAll()) as LearnVideosType[];
    if (props.editMode === null) {
      getList = getList.filter((filtered) => {
        if (videoList.findIndex((vid) => vid.key === filtered.key) === -1) {
          return filtered;
        }
      });
    }

    setContentList(getList);
  }, []);

  const getCategories = useCallback(async () => {
    const categoryList = await categoriesRepository.getAll();

    setCategories(categoryList as CategoryType[]);
  }, []);

  const getUsers = useCallback(async () => {
    const userList = await usersRepository.getAll();

    setUsers(userList as UserType[]);
  }, []);

  const createNewTrophy = useCallback(
    async (trophyName: string) => {
      setIsLoading(true);

      const create = await trophyRepository.post({ name: trophyName });

      setTrophies([...tropies, create]);
      setFormState({
        ...formState,
        trophy: { value: create.id, label: create.name }
      });

      setIsLoading(false);
    },
    [tropies, formState]
  );

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      getContentList(),
      getCategories(),
      getDepartments(),
      getTrophies(),
      getQuizList(),
      loadCompanies(),
      getUsers()
    ])
      .then((response) => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [
    getCategories,
    getUsers,
    getContentList,
    getDepartments,
    getQuizList,
    getTrophies,
    loadCompanies
  ]);

  useEffect(() => {
    console.log(titleFields);
    props.onFormStateChange(formState, titleFields);
  }, [formState]);

  const onChangeContent = useCallback(
    (item) => {
      const findContent = contentList.find(
        (content) => content.id === item.value
      );

      if (findContent === undefined) {
        return false;
      }

      setFormState({
        ...formState,
        content: item,
        type: findContent.type,
        title: findContent.name,
        imageURL: findContent.image
      });
    },
    [contentList, formState]
  );

  useEffect(() => {
    breadCrumb.setBackButton(props.backCallback);
    breadCrumb.setAdditionalCrumb({
      title: props.editMode
        ? props.editMode.title
        : I18N('createContent', language),
      onClick: undefined
    });
    breadCrumb.setTitle(
      props.editMode ? props.editMode.title : I18N('createContent', language)
    );
    const items = [];

    breadCrumb.setChildren([
      ...items,
      {
        color: 'lightGreen',
        key: 'videoInfoEdit',
        title: props.editMode
          ? I18N('updateContent', language)
          : I18N('createContent', language),
        onClick: props.onFinish,
        icon: 'save'
      }
    ]);
  }, [language, props.onEnroll, props.onFinish, props.backCallback]);

  useEffect(() => {
    if (formState.companies) {
      const selectedCompanies = formState.companies.map((i) => i.value);
      const companyOfDep = departments.map((aData) => {
        return aData.departments_companies.map((i) => {
          if (selectedCompanies.includes(i.company_id)) {
            return i.department_id;
          }
        });
      });

      let newArry = [];

      companyOfDep.map((data) => {
        data.forEach((a) => {
          if (a !== undefined) {
            newArry.push(a);
          }
        });
      });

      newArry = [...newArry];

      setFormState({
        ...formState,
        mandatoryDepartments: newArry,
        departments: newArry
      });
    }
  }, [formState.companies, departments]);

  return (
    <>
      <Backdrop
        open={isLoading}
        style={{ color: standartColors.primaryYellow, zIndex: 999 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row'
        }}
      >
        <div style={{ width: '49%', padding: 10 }}>
          <ViewBox title={I18N('file', language)}>
            <ViewBoxTab
              tabs={[
                {
                  name: I18N('selectContent', language),
                  content: (
                    <div style={{ marginTop: 10 }}>
                      <Select
                        value={formState.content}
                        theme={(template) => {
                          return {
                            ...template,
                            colors: {
                              ...template.colors,
                              ...colorForSelect(theme)
                            }
                          };
                        }}
                        options={contentList.map((content) => {
                          return {
                            value: content.id,
                            label:
                              content.name +
                              ' (' +
                              (content.type === 1
                                ? 'Video'
                                : content.type === 2
                                ? 'PDF'
                                : 'MP4 VIDEO') +
                              ')'
                          };
                        })}
                        onChange={(item) => onChangeContent(item)}
                      />
                    </div>
                  )
                },
                {
                  name: I18N('video', language),
                  content: (
                    <React.Fragment>
                      <React.Fragment>
                        {formState.url === '' ? (
                          <UploadFilesContent
                            showLoadingWhileUpload={true}
                            uploadKey="video"
                            onUpload={(url) =>
                              setFormState({ ...formState, url: url })
                            }
                            inputAttrs={{ accept: 'video/mp4' }}
                            uploadRepository={videoUserUploadRepository}
                            url={formState.url}
                          />
                        ) : (
                          <TextField
                            fullWidth
                            label={I18N('url', language)}
                            style={{ marginTop: 20 }}
                            value={formState.url}
                            disabled={true}
                          />
                        )}
                      </React.Fragment>
                    </React.Fragment>
                  )
                },
                {
                  name: I18N('pdf', language),
                  content: (
                    <UploadFilesContent
                      showLoadingWhileUpload={true}
                      uploadKey="pdf"
                      onUpload={(url) =>
                        setFormState({ ...formState, url: url })
                      }
                      inputAttrs={{ accept: 'application/pdf' }}
                      uploadRepository={videoUserUploadRepository}
                      url={formState.url}
                    />
                  )
                },
                {
                  name: I18N('quiz', language),
                  content: (
                    <div style={{ marginTop: 10 }}>
                      {formState.quiz === undefined ||
                      formState.quiz === null ? (
                        <NoCreateSelect
                          theme={(template) => {
                            return {
                              ...template,
                              colors: {
                                ...template.colors,
                                ...colorForSelect(theme)
                              }
                            };
                          }}
                          value={formState.standalone}
                          isClearable={true}
                          options={quizStandalone.map((content) => {
                            return { value: content.id, label: content.name };
                          })}
                          onChange={(item) => {
                            setFormState({
                              ...formState,
                              quiz: null,
                              standalone: item
                            });
                          }}
                        />
                      ) : (
                        <p>
                          {I18N('quizSelectedCantSelectStandalone', language)}
                        </p>
                      )}
                    </div>
                  )
                }
              ]}
              onTabChange={(currentTab) => {
                if (currentTab !== undefined) {
                  setFormState({
                    ...formState,
                    url: '',
                    type: currentTab === 1 ? 3 : 2
                  });
                }
              }}
            />
          </ViewBox>
          <ViewBox title={I18N('details', language)}>
            <LTextField
              sendInputStates={setTitleFields}
              fullWidth={true}
              value={formState.title}
              onChange={(text) => setFormState({ ...formState, title: text })}
              label={I18N('title', language)}
            />
            <div className="singleCheckWithName">
              <Checkbox
                className="checkboxColor"
                value="on"
                checked={formState.visible}
                onChange={(event, checked) => {
                  setFormState({ ...formState, visible: checked });
                }}
              />
              {I18N('isVisible', language)}
            </div>

            <div className="viewBoxTitle">
              {I18N('whichCategories', language)}
            </div>

            <Select
              theme={(template) => {
                return {
                  ...template,
                  colors: {
                    ...template.colors,
                    ...colorForSelect(theme)
                  }
                };
              }}
              value={formState.categories}
              options={categories.map((category) => {
                return {
                  value: category.id,
                  label: `${category.name} ${
                    category.user !== undefined
                      ? `(${category?.user?.firstName} ${category?.user?.lastName})`
                      : ''
                  }`
                };
              })}
              onChange={(item) =>
                setFormState({ ...formState, categories: item })
              }
            />

            <div className="marginTop20">
              <p className="viewBoxTitle">
                {I18N('nameTrophy', language, [
                  { key: 'name', value: I18N('content', language) }
                ])}
              </p>
              <Select
                theme={(template) => {
                  return {
                    ...template,
                    colors: {
                      ...template.colors,
                      ...colorForSelect(theme)
                    }
                  };
                }}
                isClearable={true}
                isMulti={false}
                onChange={(change) => {
                  setFormState({ ...formState, trophy: change });
                }}
                value={formState.trophy}
                isLoading={isLoading}
                onCreateOption={createNewTrophy}
                placeholder={I18N('selectTrophy', language)}
                options={tropies.map((trophy) => ({
                  value: trophy.id,
                  label: trophy.name
                }))}
              />
            </div>

            {(formState.standalone === undefined ||
              formState.standalone === null) && (
              <div className="marginTop20">
                <p className="viewBoxTitle">{I18N('selectQuiz', language)}</p>
                <Select
                  theme={(template) => {
                    return {
                      ...template,
                      colors: {
                        ...template.colors,
                        ...colorForSelect(theme)
                      }
                    };
                  }}
                  isClearable={true}
                  isMulti={false}
                  onChange={(change) => {
                    setFormState({
                      ...formState,
                      quiz: change,
                      standalone: null
                    });
                  }}
                  value={formState.quiz}
                  placeholder={I18N('selectQuiz', language)}
                  options={quizList.map((quiz) => ({
                    value: quiz.id,
                    label: quiz.name
                  }))}
                />
              </div>
            )}
            <div className="marginTop20">
              <p className="viewBoxTitle">
                {I18N('responsibleUser', language)}
              </p>
              <Select
                theme={(template) => {
                  return {
                    ...template,
                    colors: {
                      ...template.colors,
                      ...colorForSelect(theme)
                    }
                  };
                }}
                isClearable={true}
                isMulti={false}
                onChange={(change) => {
                  setFormState({
                    ...formState,
                    accountable: change,
                    standalone: null
                  });
                }}
                value={formState.accountable}
                placeholder={I18N('responsibleUser', language)}
                options={users.map((user) => ({
                  value: user.id,
                  label: `${user.firstName} ${user.lastName}`
                }))}
              />
            </div>
            <div className="marginTop20">
              <p className="viewBoxTitle">
                {I18N('implementationUser', language)}
              </p>
              <Select
                theme={(template) => {
                  return {
                    ...template,
                    colors: {
                      ...template.colors,
                      ...colorForSelect(theme)
                    }
                  };
                }}
                isClearable={true}
                isMulti={false}
                onChange={(change) => {
                  setFormState({
                    ...formState,
                    execution_responsible: change,
                    standalone: null
                  });
                }}
                value={formState.execution_responsible}
                placeholder={I18N('implementationUser', language)}
                options={users.map((user) => ({
                  value: user.id,
                  label: `${user.firstName} ${user.lastName}`
                }))}
              />
            </div>
          </ViewBox>
          <ViewBox title={I18N('companies', language)}>
            <SelectAutocomplete
              multiple={true}
              maxTags={3}
              options={companies.map((comp) => {
                return { value: comp.id, label: comp.name };
              })}
              value={formState.companies}
              onChange={(data) =>
                setFormState({ ...formState, companies: data as any })
              }
            />

            {/*<Select theme={(template) => {*/}
            {/*    return {*/}
            {/*        ...template,*/}
            {/*        colors: {*/}
            {/*            ...template.colors,*/}
            {/*            ...colorForSelect(theme)*/}
            {/*        }*/}
            {/*    }*/}
            {/*}} styles={{valueContainer: (styles) => {*/}
            {/*        return {*/}
            {/*            ...styles,*/}
            {/*            height: 50,*/}
            {/*            scrollbarWidth: "none",*/}

            {/*            msOverflowStyle: "none",*/}
            {/*            overflowX: "hidden",*/}
            {/*            overflowY: "scroll"*/}
            {/*        };*/}
            {/*    },}} isMulti={true} key="subCompanySelect" options={companies.map((comp) => { return {value: comp.id, label: comp.name}})} value={formState.companies} onChange={(data, ) => setFormState({...formState, companies: data as any})} />*/}
          </ViewBox>
        </div>
        <div style={{ width: '49%', padding: 10 }}>
          <ViewBox title={I18N('image', language)}>
            <UploadFilesContent
              className="newEditBox"
              showLoadingWhileUpload={true}
              inputAttrs={{ accept: 'image/*' }}
              url={formState.imageURL}
              uploadRepository={videoRepository}
              onUpload={(image) =>
                setFormState({ ...formState, imageURL: image })
              }
              uploadKey="cover"
            />
          </ViewBox>
          <ViewBox title={I18N('departments', language)}>
            <table style={{ width: '98%', borderSpacing: 0 }}>
              <thead style={{ width: '98%' }}>
                <tr style={{ borderRadius: 10 }}>
                  <th
                    style={{
                      padding: 15,
                      color: '#3E4649',
                      fontSize: 14,
                      fontWeight: 'bold'
                    }}
                  >
                    {I18N('visibleForAll', language)}
                  </th>
                  <th
                    style={{
                      padding: 15,
                      color: '#3E4649',
                      fontSize: 14,
                      fontWeight: 'bold'
                    }}
                  >
                    {I18N('requiredForAll', language)}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: 'center' }}>
                    <Android12Switch
                      checked={formState.visibleForAll}
                      value={formState.visibleForAll}
                      onChange={() => {
                        setFormState({
                          ...formState,
                          departments: !formState.visibleForAll
                            ? departments.map((i) => i.ID)
                            : [],
                          visibleForAll: !formState.visibleForAll
                        });
                      }}
                      color="warning"
                    />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Android12Switch
                      checked={formState.requiredForAll}
                      value={formState.requiredForAll}
                      onChange={() => {
                        setFormState({
                          ...formState,
                          mandatoryDepartments: !formState.requiredForAll
                            ? [...formState.departments]
                            : [],
                          requiredForAll: !formState.requiredForAll
                        });
                      }}
                      color="warning"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            {formState.companies === undefined ||
            formState.companies === null ||
            formState.companies.length < 1 ? (
              <tr style={{ width: '100%', display: 'flex', flex: 1 }}>
                <div
                  style={{
                    fontWeight: 'bold',
                    marginTop: 15,
                    textAlign: 'center',
                    width: '100%'
                  }}
                >
                  {I18N('pleaseSelectCompaniesFirst', language)}
                </div>
              </tr>
            ) : (
              <table style={{ width: '98%', borderSpacing: 0 }}>
                <thead style={{ width: '98%' }}>
                  <tr style={{ borderRadius: 10 }}>
                    <th
                      style={{
                        padding: 15,
                        textAlign: 'left',
                        color: '#3E4649',
                        fontSize: 14,
                        fontWeight: 'bold'
                      }}
                    >
                      {I18N('department', language)}
                    </th>
                    <th
                      style={{
                        padding: 15,
                        textAlign: 'left',
                        color: '#3E4649',
                        fontSize: 14,
                        fontWeight: 'bold'
                      }}
                    >
                      {I18N('available', language)}
                    </th>
                    <th
                      style={{
                        padding: 15,
                        textAlign: 'left',
                        color: '#3E4649',
                        fontSize: 14,
                        fontWeight: 'bold'
                      }}
                    >
                      {I18N('mandatory', language)}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {departments.map((item) => {
                    if (!formState.companies) {
                      return;
                    }

                    const selectedCompanies = formState.companies.map(
                      (i) => i.value
                    );
                    const companyOfDep = item.departments_companies.findIndex(
                      (i) => selectedCompanies.includes(i.company_id)
                    );
                    if (companyOfDep !== -1) {
                      return (
                        <tr>
                          <td style={{ padding: 15 }}>{item.name}</td>
                          <td style={{ padding: 15 }}>
                            <Android12Switch
                              disabled={formState.visibleForAll}
                              checked={formState.departments.includes(item.ID)}
                              onChange={() => {
                                const check = formState.departments.findIndex(
                                  (i) => i === item.ID
                                );
                                if (check === -1) {
                                  setFormState({
                                    ...formState,
                                    departments: [
                                      ...formState.departments,
                                      item.ID
                                    ]
                                  });
                                } else {
                                  formState.departments.splice(check, 1);
                                  setFormState({
                                    ...formState,
                                    departments: [...formState.departments]
                                  });
                                }
                              }}
                              color="warning"
                            />
                          </td>
                          <td style={{ padding: 15 }}>
                            <Android12Switch
                              disabled={
                                formState.requiredForAll ||
                                (!formState.visibleForAll &&
                                  !formState.departments.includes(item.ID))
                              }
                              checked={formState.mandatoryDepartments.includes(
                                item.ID
                              )}
                              onChange={() => {
                                const check =
                                  formState.mandatoryDepartments.findIndex(
                                    (i) => i === item.ID
                                  );
                                if (check === -1) {
                                  setFormState({
                                    ...formState,
                                    mandatoryDepartments: [
                                      ...formState.mandatoryDepartments,
                                      item.ID
                                    ]
                                  });
                                } else {
                                  formState.mandatoryDepartments.splice(
                                    check,
                                    1
                                  );
                                  setFormState({
                                    ...formState,
                                    mandatoryDepartments: [
                                      ...formState.mandatoryDepartments
                                    ]
                                  });
                                }
                              }}
                              color="warning"
                            />
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            )}
          </ViewBox>
        </div>
      </div>
    </>
  );
}
