import React, {useCallback, useEffect, useState} from 'react';
import { Icon } from '@iconify/react';
import loading from '../assets/loading2.svg';
import {
    TextField,
    IconButton,
    InputAdornment,
    Button, CircularProgress, Backdrop, styled
} from '@mui/material';
import eyeFill from '@iconify-icons/eva/eye-fill';
import eyeOffFill from '@iconify-icons/eva/eye-off-fill';
import {Link, useHistory} from 'react-router-dom'
import { standartColors } from '../assets/colors/Colors';
import axios from "axios";
import {BACKEND_PRODUCTION_URL, BACKEND_URL, TEST_ENDPOINT} from "../assets/AppConstants";
import jwt_decode from "jwt-decode";
import {useLoginState} from "../States/UserState";
import {useLanguageState} from "../States/LanguageState";
import {UsersRepository} from "../Repositories/UsersRepository";
import {I18N} from "../i18n/i18n";
import {COOKIE_TOKEN, expireCookie, getToken, setCookie} from "../Repositories/Cookies";
import loginbgsvg from '../assets/login-bg.svg';
import loginFinger from '../assets/loginFinger.svg';
import headerLogo from '../assets/headerLogo.svg';
import {useAuthServiceState} from "../States/AuthServiceState";
import {params} from "../App";
import {useTheme} from "@mui/material";
import SettingsSidebar from "../ViewComponents/SettingsSideBar/settings-sidebar";
import {CompanyRepository} from "../Repositories/CompanyRepository";
import {useSnackbar} from "notistack";
import PasswordStrengthBar from "react-password-strength-bar";
import {PassStringCreator} from "../Helpers/PassStringCreator";
const userRepository = new UsersRepository();
const companiesRepository = new CompanyRepository();


type Props = {
    login: (client: string, username: string, password: string) => void;
    client: string;
    setLoggedIn: () => void;
}

const CssTextField = styled(TextField)({
    color: "#fff",
    '& .MuiInputBase-input': {
      color: "#fff"
    },
    '& label.Mui-focused': {
        color: '#fff',
    },
    '& label.Mui': {
        color: '#fff',
    },
    '& .MuiInputLabel-root':{
      color: "#fff"
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#fff',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#fff',
        },
        '&:hover fieldset': {
            borderColor: '#fff',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#fff',
        },
    },
});

const getClient = getToken();

export function Login(props: Props) {

    const theme = useTheme<any>();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [client, setClient] = useState(getClient === undefined ? 'realm-management' : getClient.toLocaleLowerCase());
    const [showPassword, setShowPassword] = useState(false);
    const userState = useLoginState();
    const languageState = useLanguageState();
    const authServiceState = useAuthServiceState();
    const history = useHistory();
    const [isUpdated, setIsUpdated] = useState(false);
    const [forgetPassword, setForgetPassword] = useState(false);
    const [forgetPasswordStep2, setForgetPasswordStep2] = useState(false);
    const [token, setToken] = useState('');
    const [loadingLogin, setLoadingLogin] = useState(true);
    const [firstTimeToken, setFirstTimeToken] = useState(null);
    const [firstTimeLogin, setFirstTimeLogin] = useState(false);
    const [passwordSecond, setPasswordSecond] = useState('');
    const [showPasswordSecond, setShowPasswordSecond] = useState(false);
    const [loadingFirstTime, setLoadingFirstTime] = useState(false);
    const [resetLoading, setResetLoading] = useState(false);
    useEffect(() => {

        console.log(window.location.href);
        const token = window.location.href.split('?token=');
        console.log(token);
        if(token[1] !== undefined){
            if(token[1].length > 20){
                setForgetPassword(true);
                setForgetPasswordStep2(true);
                setToken(token[1]);
            }
        }

    }, []);


    useEffect(() => {

        const firstTime = window.location.href.split('?firstTimeLogin=');
        if(firstTime[1] !== undefined){
            const splittedLogin = firstTime[1].split('cmpl__');
            if(splittedLogin.length === 3){
                setLoadingFirstTime(true);
                loginUsingKeycloak(splittedLogin[0],splittedLogin[1],splittedLogin[2]).then(() => setLoadingFirstTime(false)).catch(() => setLoadingFirstTime(false))
            }
        }

    }, []);


    const loadCompanies = useCallback(async () => {
       const compData = await companiesRepository.getSubCompanies();
       return compData;
    }, []);


    const getLoginData = useCallback(async (token: string, refreshToken: string) => {
        //Setting base axios url
        axios.defaults.baseURL = BACKEND_URL;

        //Setting auth token to axios headers

        axios.defaults.headers = {
            Authorization: `Bearer ${token}`,
        }

        //sending request to our end to retrive use information

        const loginStatus = await axios.get<any>('/users/me').then((response) => response).catch(async () => {
            return {
                status: "err"
            }
        });

        console.log(loginStatus);

        if(loginStatus.status !== 200){

            setLoadingLogin(false);
            setIsUpdated(true);

            await enqueueSnackbar(I18N('pleaseCheckUsernamePassword', languageState.language), {variant: "error"})
            userState.setToken(null);
            return;

        }

        //@ts-ignore
        if(loginStatus.data.innerData.needToResetPassword === 1){
            setFirstTimeLogin(true);
            setFirstTimeToken(token);
            //@ts-ignore
            setClient(loginStatus.data.azp);
            setPassword('');
            //@ts-ignore
            setUsername(loginStatus.data.preferred_username.replace(`_${loginStatus.data.azp}`, ''));
            return;
        }



        if(loginStatus.status !== 200){

            setLoadingLogin(false);
            setIsUpdated(true);

            await enqueueSnackbar(I18N('pleaseCheckUsernamePassword', languageState.language), {variant: "error"})
            userState.setToken(null);
            return;

        }

        setIsUpdated(true);


        //CHECKING IF WE ARE USING API AUTH METHOD
        if(authServiceState.state){
//@ts-ignore
            await axios.post(`/apikeys/loginRedirect`, {user_id: loginStatus.data.innerData.id}).then((respon) => {
                window.location.href = authServiceState.redirectUrl + "?token=" + respon.data.token;
            });
            return;
        }

        userState.setToken(jwt_decode(token));

        setCookie('refresh_token', refreshToken);
        if(client === "" || client === "realm-management"){
            const tokenParse:any = jwt_decode(token);
            console.log(tokenParse);
            setCookie(COOKIE_TOKEN, tokenParse.azp);
        }else{
            setCookie(COOKIE_TOKEN, client.toLocaleLowerCase());
        }

        const compToken = localStorage.getItem("user_company");

        const compList = await loadCompanies();


        const activatedCompanies = compList.filter((i) => {
            console.log(i.users_companies);
            //@ts-ignore
            const activeUserIndex = i.users_companies?.findIndex((b) => b.user_id === loginStatus.data.innerData.id);
            if(activeUserIndex !== -1){
                return !!i.users_companies[activeUserIndex].active;
            }else{
                return false;
            }
        });


        if(client !== 'adminclient'){
            //@ts-ignore
            if(loginStatus.data.innerData.group.findIndex((i) => {
                return i.name === 'Superadmin'
            }) !== -1){
                localStorage.setItem("iaxx1", "1");
            }else{
                localStorage.setItem("iaxx1", "0");
            }

                //@ts-ignore
                if(loginStatus.data.innerData.group.findIndex((i) => {
                    return i.name === 'Superadmin'
                }) !== -1) {
                    userState.setCompany(activatedCompanies.map((i) => i.id));
                    axios.defaults.headers['x-auth-company'] = activatedCompanies.map((i) => i.id).join(',');
                }else{
                    userState.setCompany(activatedCompanies.map((i) => i.id));
                    axios.defaults.headers['x-auth-company'] = activatedCompanies.map((i) => i.id);
                }




        }






        if(client === "adminclient"){
            history.push("/superadmin")
        }else{
            if(history.location.pathname === "/"){
                if(client === "adminclient"){
                    history.push("/superadmin")
                }else{
                    history.push('/Dashboard')
                }
            }else if(history.location.pathname === "/superadmin" || history.location.pathname === '/superadminconfiguration'){
                history.push('/Dashboard')
            }
        }


        //@ts-ignore
        userState.initData(loginStatus.data.innerData.photo, loginStatus.data.innerData.phone, loginStatus.data);

        if(client !== 'adminclient'){
            //@ts-ignore
            languageState.setLanguage(loginStatus.data.innerData.language);

        }
        props.setLoggedIn();


    }, [authServiceState.redirectUrl, authServiceState.state, client, history, languageState, props, userState, username]);


    useEffect(() => {

        ///apikeys/loginUsingToken
        if(params.authToken !== null){
            axios.defaults.baseURL = BACKEND_URL;
            let connectionURL = '';
            if(params.loginForClient !== null){
                if(BACKEND_URL.includes("localhost") || BACKEND_URL.includes("goriscon.app")){
                    connectionURL = BACKEND_PRODUCTION_URL + '/backend';
                }else{
                    connectionURL = TEST_ENDPOINT + '/backend';
                }
            }

            axios.get(`${connectionURL}/apikeys/loginUsingToken`, {
                params: {
                    token: params.authToken
                }
            }).then(async (res) => {
                if(params.loginForClient !== null){
                    return res.data.access_token;
                }
                await getLoginData(res.data.access_token, res.data.refresh_token)
                //alert(JSON.stringify(res.data));
            }).catch((er) => {
                alert(JSON.stringify(er.response.data));
            })
        }

    }, []);


    useEffect(() => {
        setLoadingLogin(true);
        const getRefreshToken = getToken(undefined, 'refresh_token');
        const uCompany = localStorage.getItem('user_company');
        const getClient = getToken();
        if(getRefreshToken !== undefined && getClient !== undefined) {
            userRepository.refreshKeycloak(getClient.toLocaleLowerCase(), getRefreshToken).then(async (response) => {
                if(response.status){
                    console.log(response);
                    await getLoginData(response.data.access_token, response.data.refresh_token);
                    setIsUpdated(true);
                    setCookie('refresh_token', response.data.refresh_token);
                    setCookie(COOKIE_TOKEN, getClient.toLocaleLowerCase());
                    // loadCompanies().then((response) => {
                    //     if(uCompany === null){
                    //         localStorage.setItem('user_company', response[0].id);
                    //         userState.setCompany([response[0].id]);
                    //         axios.defaults.headers['x-auth-company'] = response[0].id;
                    //     }else{
                    //         const splitToken = uCompany.split(',');
                    //         const companyDataList = response.filter((i) => {
                    //             return splitToken.includes(i.id.toString());
                    //         })
                    //
                    //         if(companyDataList.length < 1){
                    //             localStorage.setItem('user_company', response[0].id);
                    //             axios.defaults.headers['x-auth-company'] = response[0].id;
                    //             userState.setCompany([response[0].id]);
                    //         }else{
                    //             localStorage.setItem("user_company", companyDataList.map((i) => i.id).join(','));
                    //             axios.defaults.headers['x-auth-company'] = companyDataList.map((i) => i.id).join(',');
                    //             userState.setCompany(companyDataList.map((i) => i.id));
                    //         }
                    //     }
                    //     props.setLoggedIn();
                    // })

                }else{
                    expireCookie(COOKIE_TOKEN);
                    expireCookie('refresh_token');
                    localStorage.removeItem('user_company');
                    if(window.location.pathname === ''){}else if(window.location.pathname === '/'){}else{
                        setLoadingLogin(false);
                        setIsUpdated(true);

                        await enqueueSnackbar(I18N('authExpired', languageState.language), {variant: "error"})

                    }


                }
            }).catch((err) => {
                setLoadingLogin(false);
                setIsUpdated(true);
            })

        }else {
            setIsUpdated(true);
            setLoadingLogin(false);
        }
    }, []);



    const resetPasswordSave = useCallback(async () => {

        if(password !== passwordRepeat){

            await enqueueSnackbar(I18N('passwordsDoesNotMatch', languageState.language), {variant: "error"});
            return;
        }

        setResetLoading(true);
        const reset = await userRepository.resetPasswordSave(client.toLocaleLowerCase(), token, password).catch((e) => {
            setResetLoading(false);
            return e;
        })
        if(reset.status){
            setToken('');
            setUsername('');
            setPassword('');
            setForgetPassword(false);
            setForgetPasswordStep2(false);
            setResetLoading(false);

            await enqueueSnackbar(I18N('successfullyChanged', languageState.language), {variant: "success"})

        }else{
            setResetLoading(false);
            console.log(reset);
            //capitalLetters: true
            //
            // digits: false
            //
            // minLenght: 12
            //
            // regexData: {}
            //
            // regexString: "(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[#?!@$%^&*-]).{12,}"
            //
            // specialChars: true

            if(reset.regexString){

                let buildText = buildString(reset);

                await enqueueSnackbar(buildText, {variant: "error", style: { whiteSpace: 'pre-line' }})
            }else{

                await enqueueSnackbar(I18N('unexpectedError', languageState.language), {variant: "error"})

            }


        }

    }, [client, token, password, languageState.language, passwordRepeat]);


    const buildString = useCallback((reset: any) => {

        return PassStringCreator(reset, languageState.language);

    }, [languageState]);

    const resetPassword = useCallback(async () => {
        setResetLoading(true);
        const reset = await userRepository.resetPassword(client.toLocaleLowerCase(), username).catch((e) => {
            setResetLoading(false);
            return e;
        })

        if(reset.status){
            setUsername('');
            setPassword('');
            setForgetPassword(false);
            setResetLoading(false);
            await enqueueSnackbar(I18N('emailSent', languageState.language), {variant: "success"})

        }else{
            setResetLoading(false);

            await enqueueSnackbar(I18N('wrongCredentials', languageState.language), {variant: "error"})

        }

    }, [languageState, client, username]);


    //Sending username password and client to keycloak to get response
    const getLoginDataFromKeycloak = useCallback(async (clientName, userName, pass) => {
        return await userRepository.loginUsingKeyCloak(clientName.toLocaleLowerCase(), `${userName}_${clientName.toLocaleLowerCase()}`, pass);
    }, []);

    const loginUsingKeycloak = useCallback(async (cli?: string, usr?: string, psw?: string) => {
       setLoadingLogin(true);
       const loginData = await getLoginDataFromKeycloak(cli ? cli.toLocaleLowerCase() : client.toLocaleLowerCase(), usr ? usr : username, psw ? psw : password);
       if(loginData.status){
               //When getting success response from keycloak sending request to our server and saving tokens
               await getLoginData(loginData.data.access_token, loginData.data.refresh_token);

           setLoadingLogin(false);
       }else{
           setLoadingLogin(false);
           await enqueueSnackbar(I18N('wrongCredentials', languageState.language), {variant: "error"})

       }

    }, [getLoginDataFromKeycloak, client, username, password, getLoginData, languageState.language]);


    //Handling key strokes to get if its enter event
    const enterHandler = useCallback((event) => {

        if (event.keyCode === 13) {
            loginUsingKeycloak().then(undefined);
        }

    }, [loginUsingKeycloak]);
    //END OF HANDLING


    //Adding event listener to listen key strokes to get enter key
    useEffect(() => {

        document.addEventListener("keypress", enterHandler);

        return () => {
            document.removeEventListener("keypress", enterHandler);
        }
    }, [enterHandler]);
    //END OF EVENT LISTENER


    const firstTimeLoginSave = useCallback(async () => {

        if(password !== passwordSecond){
            await enqueueSnackbar(I18N('pleaseEnterSamePassword', languageState.language), {variant: "error"})
            return;
        }

        axios.defaults.headers = {
            Authorization: `Bearer ${firstTimeToken}`,
        }

        await axios.post(BACKEND_URL + '/users/bulk/passReset', {
            password: password,
        }).then(async (reset) => {
            if(reset.data.status){

                await loginUsingKeycloak(client, username, password);
            }else{
                if(reset.data.regexString){

                    let buildText = buildString(reset.data);

                    await enqueueSnackbar(buildText, {variant: "error", style: { whiteSpace: 'pre-line' }})
                }else{

                    await enqueueSnackbar(I18N('unexpectedError', languageState.language), {variant: "error"})

                }

            }

        }).catch(async (err) => {
            await enqueueSnackbar(I18N('unexpectedError', languageState.language), {variant: "error"})
        })


    }, [password, passwordSecond, firstTimeToken, languageState.language, loginUsingKeycloak, client, username]);

    return (
        <div>
            {isUpdated ? (
                <div style={{ display: 'flex', height: '100vh', flexDirection: 'row', backgroundBlendMode: 'lighten', backgroundImage: 'url('+(theme.palette.mode === 'dark' ? loginbgsvg : loginbgsvg)+')', backgroundPosition: 'center', backgroundRepeat: "no-repeat", backgroundSize: 'cover'}}>
                    <Backdrop
                        open={loadingLogin || loadingFirstTime}
                        style={{ color: standartColors.primaryYellow, zIndex: 1 }}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <SettingsSidebar />
                    <div className="headerLogo"><img src={headerLogo} /></div>

                    <div style={{background: "rgba(62, 70, 73, .7)", display: "flex", height: 'auto', width: '75%', margin: '0 auto', padding: 40, borderRadius: 10, justifyContent: "center", alignItems: "center", alignSelf: "center", justifySelf: "center", alignContent: "center"}}>
                        <div>
                            <img src={loginFinger} />
                        </div>
                        <div>
                            <div>
                                <div style={styles.headlineContainer}>
                                    <div style={{...styles.headline, color: "#fff"}}>Compliness.</div>
                                    <div style={{...styles.headline2, color: '#fff'}}>{I18N('headlineText', languageState.language)}</div>
                                </div>
                                <div className="loginBox">

                                    {firstTimeLogin ? (
                                        <React.Fragment>
                                            <p style={styles.headline2}>Please enter new password to continue</p>
                                            <CssTextField
                                                sx={{color: "#fff"}}
                                                fullWidth
                                                autoComplete="false"
                                                type="text"
                                                disabled={true}
                                                style={{ marginTop: 22 }}
                                                label={I18N('client', languageState.language)}
                                                value={client}
                                                onChange={(text) => setClient(text.target.value.toLocaleLowerCase())}
                                            />
                                            <CssTextField
                                                fullWidth
                                                type="text"
                                                autoComplete="false"
                                                label={I18N("username", languageState.language)}
                                                style={{ marginTop: 22 }}
                                                disabled={true}
                                                value={username}
                                            />
                                            <CssTextField
                                                fullWidth
                                                type={showPassword ? 'text' : 'password'}
                                                label={I18N("password", languageState.language)}
                                                value={password}
                                                autoComplete="false"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position={"end"}>
                                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                                <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                style={{ marginTop: 22 }}
                                                onChange={(text) => setPassword(text.target.value)}
                                            />
                                            <CssTextField
                                                fullWidth
                                                type={showPasswordSecond ? 'text' : 'password'}
                                                label={I18N("passwordRepeat", languageState.language)}
                                                value={passwordSecond}
                                                autoComplete="false"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position={"end"}>
                                                            <IconButton onClick={() => setShowPasswordSecond(!showPasswordSecond)} edge="end">
                                                                <Icon icon={showPasswordSecond ? eyeFill : eyeOffFill} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                style={{ marginTop: 22 }}
                                                onChange={(text) => setPasswordSecond(text.target.value)}
                                            />

                                            <div style={styles.flexButton}>
                                                <Button style={styles.button} fullWidth variant="contained" onClick={firstTimeLoginSave}>Change Password & Login</Button>
                                            </div>
                                        </React.Fragment>

                                    ): (
                                        <>
                                            {forgetPassword ? (
                                                <React.Fragment>
                                                    {forgetPasswordStep2 ? (
                                                        <React.Fragment>
                                                            <Backdrop
                                                                open={resetLoading}
                                                                style={{ color: standartColors.primaryYellow, zIndex: 1 }}
                                                            >
                                                                <CircularProgress color="inherit" />
                                                            </Backdrop>
                                                            <CssTextField
                                                                fullWidth
                                                                type="text"
                                                                label={I18N("resetKey", languageState.language)}
                                                                disabled={true}
                                                                value={token}
                                                                autoComplete="false"
                                                            />
                                                            <CssTextField
                                                                fullWidth
                                                                type="text"
                                                                autoComplete="false"
                                                                style={{ marginTop: 22 }}
                                                                label={I18N("client", languageState.language)}
                                                                onChange={(text) => setClient(text.target.value.toLocaleLowerCase())}
                                                            />
                                                            <CssTextField
                                                                fullWidth
                                                                type={showPassword ? 'text' : 'password'}
                                                                label={I18N("password", languageState.language)}
                                                                value={password}
                                                                autoComplete="false"
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position={"end"}>
                                                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                                                <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                style={{ marginTop: 22 }}
                                                                onChange={(text) => setPassword(text.target.value)}
                                                            />
                                                            {/*<PasswordStrengthBar scoreWords={[I18N('weak', languageState.language), I18N('ok', languageState.language), I18N('good', languageState.language), I18N('strong', languageState.language), I18N('stronger', languageState.language)]} password={password} />*/}
                                                            <CssTextField
                                                                fullWidth
                                                                type={showPassword ? 'text' : 'password'}
                                                                label={I18N("passwordRepeat", languageState.language)}
                                                                value={passwordRepeat}
                                                                autoComplete="false"
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position={"end"}>
                                                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                                                <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                style={{ marginTop: 22 }}
                                                                onChange={(text) => setPasswordRepeat(text.target.value)}
                                                            />
                                                            <div style={styles.flexButton}>
                                                                <Button style={styles.button} fullWidth variant="contained" onClick={resetPasswordSave}>{I18N("changePassword", languageState.language)}</Button>
                                                            </div>
                                                        </React.Fragment>
                                                    ): (
                                                        <React.Fragment>
                                                            <Backdrop
                                                                open={resetLoading}
                                                                style={{ color: standartColors.primaryYellow, zIndex: 1 }}
                                                            >
                                                                <CircularProgress color="inherit" />
                                                            </Backdrop>
                                                            <CssTextField
                                                                fullWidth
                                                                type="text"
                                                                autoComplete="false"
                                                                label="Client"
                                                                onChange={(text) => setClient(text.target.value.toLocaleLowerCase())}
                                                            />
                                                            <CssTextField
                                                                fullWidth
                                                                type="text"
                                                                autoComplete="false"
                                                                label={I18N("username", languageState.language)}
                                                                value={username}
                                                                style={{ marginTop: 22 }}
                                                                onChange={(text) => setUsername(text.target.value)}
                                                            />
                                                            <div style={styles.flexButton}>
                                                                <Button style={styles.buttonBack} fullWidth variant="contained" onClick={() => setForgetPassword(false)}>{I18N("back", languageState.language)}</Button>
                                                                <Button style={styles.button} fullWidth variant="contained" onClick={resetPassword}>{I18N("resetPassword", languageState.language)}</Button>
                                                            </div>
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <CssTextField
                                                        fullWidth
                                                        type="text"
                                                        label="Client"
                                                        autoComplete="false"
                                                        //value={client}
                                                        onChange={(text) => setClient(text.target.value.toLocaleLowerCase())}
                                                    />
                                                    <CssTextField
                                                        fullWidth
                                                        type="text"
                                                        autoComplete="false"
                                                        label={I18N('username', languageState.language)}
                                                        value={username}
                                                        style={{ marginTop: 22 }}
                                                        onChange={(text) => setUsername(text.target.value)}
                                                    />
                                                    <CssTextField
                                                        fullWidth
                                                        type={showPassword ? 'text' : 'password'}
                                                        label={I18N('password', languageState.language)}
                                                        value={password}
                                                        autoComplete="false"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position={"end"}>
                                                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        style={{ marginTop: 22 }}
                                                        onChange={(text) => setPassword(text.target.value)}
                                                    />
                                                    <div style={{...styles.forgotPsw, color: "#fff"}} onClick={() => setForgetPassword(true)}>
                                                        {I18N('forgotPassword', languageState.language)}
                                                    </div>
                                                    <Link style={{ textDecoration: 'none' }} to={`${client === "adminclient" ? "/superadmin" : "/Dashboard"}`}>
                                                        <Button style={styles.button} fullWidth variant="contained"
                                                                onClick={() => loginUsingKeycloak()}
                                                        >
                                                            {I18N('login', languageState.language)}
                                                        </Button>
                                                    </Link>
                                                </React.Fragment>
                                            )}
                                        </>
                                    )}


                                    {/*<div className="bottomCube">*/}
                                    {/*    <img src={CubeSVG} />*/}
                                    {/*</div>*/}
                                    <div className="bottomLinks">
                                        <a target="_blank" href="https://goriscon.de/de/datenschutzhinweis_goriscon" rel="noreferrer">{I18N('privacyPolicy', languageState.language)}</a>
                                        <a target="_blank" href="https://goriscon.de/de/impressumgoriscon" rel="noreferrer">{I18N('imprint', languageState.language)}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ): (
                <>
                {/* @ts-ignore */}
                <div style={{backgroundColor: theme.palette.mode === 'dark' ? ' rgb(0,0,0)' : 'rgb(241, 242, 243)', width: '100%', height: window.innerHeight, display: "flex", justifyItems: "center", justifyContent: "center"}}>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <img src={loading} />
                    </div>
                </div>
                </>
            )}

        </div>
    )
}


const styles = {

    leftCard: {
        width: '40%', height: '98vh', marginTop: '1vh', marginLeft: 10, color: '212B36', boxShadow: '0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)'
    },
    rightPageContainer: {
        // backgroundColor: 'green',
        height: '100vh',
        width: '95%',
        marginLeft: '2.5%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'

    },
    flexButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    } as React.CSSProperties,

    buttonBack: {
        backgroundColor: standartColors.circleBlue,
        color: standartColors.white,
        width: 150,
        marginRight: 30,
        marginTop: 30,
        borderRadius: 10,
        height: 45,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    button: {
        backgroundColor: standartColors.primaryYellow,
        color: standartColors.black,
        marginTop: 30,
        borderRadius: 10,
        height: 45,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    forgotPsw: {
        cursor: 'pointer',
        marginTop: 10,
        marginLeft: 15,
        width: '100%',
        height: 18,
        display: 'flex',
        justifyContent: 'flex-start',
        color: standartColors.black,
        fontSize: 14
    },
    buttonText: {
        fontSize: 18,
        fontWeight: 400
    },
    headlineContainer: {
        paddingLeft: 20,
        height: "auto",
        marginBottom: 10,
        width: '100%',
        display: 'flex',
        flexDirection: "column"
    } as React.CSSProperties,
    headline: {
        fontWeight: 400,
        fontSize: 24,
        color: standartColors.black,
        // height: '40%',
        display: 'flex',
        justifyContent: 'flex-start'
    },
    headline2: {
        display: 'flex',
        justifyContent: 'flex-start',
        color: standartColors.subTitleHeadline,
        marginTop: 18

    }
}
