import React, {useCallback} from "react";
import { useLanguageState } from "../States/LanguageState";
import { I18N } from "../i18n/i18n";
import { Backdrop, CircularProgress } from "@mui/material";
import { standartColors } from "../assets/colors/Colors";
import BackSVG from '../assets/ack2.svg';
import plusButton from '../assets/plusButton.svg';
import saveButton from '../assets/saveButton.svg'
import eyeButton from '../assets/eye.svg';
import downloadButton from '../assets/downpaper.svg';
import archiveIcon from '../assets/archiveBox.svg'
interface Props {
    text: string;
    onClick: () => void;
    isLoading: boolean;
    smallText?: string;
    deleteButton?: boolean
    smallButton?: boolean;
    withLoadingInFullScreen?: boolean;
    backButton?: boolean;
    isLeft?: boolean;
    disabled?: boolean;
    color?: 'yellow' | 'green' | 'lightGreen' | 'dark' | 'transparent' | 'gray' | 'red'
    icon?: 'plus' | 'save' | 'eye' | 'download' | 'archive'
    style?: any;
    backText?: string;
}

export default function FormButton(props: Props) {


    const language = useLanguageState(state => state.language);

    const iconReturner = useCallback(() => {
        switch (props.icon){
            case "plus": {
                return (<img src={plusButton} style={{marginRight: 20}} />)
            }
            case "save": {
                return (<img src={saveButton} style={{marginRight: 20}} />)
            }
            case "eye": {
                return (<img src={eyeButton} style={{marginRight:20}} />)
            }
            case "download": {
                return (<img src={downloadButton} style={{marginRight:20}} />)
            }
            case 'archive': {
              return (<img src={archiveIcon} width={20} style={{marginRight:20}} />);
            }
            default: {
                return null;
            }
        }
    }, [props.icon])


    return (
        <>

            <div className="buttonHolder" style={{justifyContent: props.isLeft ? 'flex-start' : 'flex-end', ...props.style}}>
                {props.color === 'transparent' ? (
                    <div style={{marginTop: 65}}></div>
                ): (<div className={`${props.disabled ? 'buttonDisabled' : (` ${props.smallButton ? 'smallButton' : 'buttonStyle' } ${props.deleteButton ? 'deleteButton' : ''} ${props.color ?? "yellow"}`)} ${props.smallText !== undefined ? 'hasText' : ''}`} style={{justifyContent: "center", alignItems: "center", alignContent: "center", display: "flex", flexDirection: "row", position: "relative"}} onClick={props.isLoading ? undefined : props.onClick}>
                    {props.backButton ? (
                        <React.Fragment>
                            <img src={BackSVG} width={14} style={{marginRight: 10}} /> {props.backText && props.backText}
                        </React.Fragment>
                    ): (
                        <React.Fragment>
                            {props.icon && (
                                <>{iconReturner()}</>
                            )}
                            {props.isLoading ? I18N('loading', language) : props.text}
                            {!props.isLoading && props.smallText !== undefined && (<small className="buttonSmallText">{props.smallText}</small>)}
                        </React.Fragment>
                    )}
                </div>)}

            </div >

            <Backdrop
                open={(props.isLoading && props.withLoadingInFullScreen)}
                style={{ color: standartColors.primaryYellow, zIndex:999 }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )

}
