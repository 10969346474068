import { Drawer, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useLanguageState } from '../../States/LanguageState';
import { I18N } from '../../i18n/i18n';
import { useTheme } from '@mui/material';
import FormButton from '../FormButton';
import { SidebarContainer, SidebarDrawer } from './Drawer';
import { TrophiesRepository } from '../../Repositories/TrophiesRepository';
import { CategoriesRepository } from '../../Repositories/CategoriesRepository';
import { QuizRepository } from '../../Repositories/QuizRepository';
import { TrophiesTypes } from '../../Types/TrophiesTypes';
import { CategoryType } from '../../Types/CategoryType';
import { default as NoCreateSelect, OptionsType } from 'react-select';
import { colorForSelect } from '../../ComplinessTheme';
import Select from 'react-select/creatable';
import { VideoRepository } from '../../Repositories/VideoRepository';
import { OptionTypeBase } from 'react-select/src/types';
import LTextField from '../LTextField';
import { DrawerConfigSx } from '../DrawerConfigSx';
import ViewBox from '../ViewBox';
import SelectAutocomplete from '../SelectAutoComplete';
import { UsersRepository } from '../../Repositories/UsersRepository';
import { UserType } from '../../Types/UserType';

const usersRepository = new UsersRepository();

const trophyRepository = new TrophiesRepository();
const categoriesRepository = new CategoriesRepository();
const quizRepository = new QuizRepository();
const videoRepository = new VideoRepository();

const GeneralContentBar = (props: {
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
  content: any;
  setContent: (data) => void;
}) => {
  const language = useLanguageState();
  const theme = useTheme();
  const [categories, setCategories] = useState([]);
  const [quizes, setQuizes] = useState([]);
  const [trophies, setTrophies] = useState([]);
  const [users, setUsers] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [titleFields, setTitleFields] = useState(
    props.content.video_titles.map((i) => {
      return {
        value: i.title,
        key: i.key.toUpperCase()
      };
    })
  );

  const [formState, setFormState] = useState<{
    title: string;
    categories: OptionsType<any>;
    trophy: OptionTypeBase;
    quiz: OptionTypeBase;
    accountable: OptionTypeBase;
    execution_responsible: OptionTypeBase;
  }>({
    title: '',
    categories: [],
    trophy: undefined,
    quiz: undefined,
    accountable: undefined,
    execution_responsible: undefined
  });

  const getQuizList = useCallback(async () => {
    const list = await quizRepository.getAll();
    //@ts-ignore
    setQuizes(list.filter((quiz) => quiz.status));
    //@ts-ignore
    return list.filter((quiz) => quiz.status) as any[];
  }, []);

  const getTrophies = useCallback(async () => {
    const trophyList = await trophyRepository.getAll();

    setTrophies(trophyList as TrophiesTypes[]);
    return trophyList as TrophiesTypes[];
  }, []);

  const getCategories = useCallback(async () => {
    const categoryList = await categoriesRepository.getAll();

    setCategories(categoryList as CategoryType[]);
    return categoryList as CategoryType[];
  }, []);

  const getUsers = useCallback(async () => {
    const usersList = await usersRepository.getAll();

    setUsers(usersList as UserType[]);
    return usersList as UserType[];
  }, []);

  const updateFormWithResponseData = useCallback(
    (
      categories: CategoryType[],
      trophies: TrophiesTypes[],
      quizes: any[],
      users: UserType[]
    ) => {
      const contentCurrentCategories = props.content.video_categories.map(
        (i) => i.category_id
      );
      let selectedTrophy = undefined;
      let selectedQuiz = undefined;
      let selectedAccountable = undefined;
      let selectedExecutionResponsible = undefined;
      const selectedCategories = categories
        .filter((i) => contentCurrentCategories.includes(i.id))
        .map((i) => {
          return { label: i.name, value: i.id };
        });

      const findTrophy = trophies.find(
        (i) => i.id === props.content.trophies.id
      );

      if (findTrophy) {
        selectedTrophy = { label: findTrophy.name, value: findTrophy.id };
      }

      const findAccountable = users.find(
        (i) => i.id === props.content.accountable_id
      );

      const findExecutionResponsible = users.find(
        (i) => i.id === props.content.execution_responsible_id
      );

      if (findTrophy) {
        selectedTrophy = { label: findTrophy.name, value: findTrophy.id };
      }

      if (findAccountable) {
        selectedAccountable = {
          label: `${findAccountable.firstName} ${findAccountable.lastName}`,
          value: findAccountable.id
        };
      }

      if (findExecutionResponsible) {
        selectedExecutionResponsible = {
          label: `${findExecutionResponsible.firstName} ${findExecutionResponsible.lastName}`,
          value: findExecutionResponsible.id
        };
      }

      if (props.content.quizes !== null) {
        const findQuiz = quizes.find((i) => i.id === props.content.quizes.id);
        if (findQuiz) {
          selectedQuiz = { label: findQuiz.name, value: findQuiz.id };
        }
      }

      setFormState({
        title: props.content.title,
        categories: selectedCategories,
        quiz: selectedQuiz,
        trophy: selectedTrophy,
        accountable: selectedAccountable,
        execution_responsible: selectedExecutionResponsible
      });
    },
    [props.content]
  );

  //Load Required Data
  useEffect(() => {
    setLoadingData(true);
    Promise.all([getCategories(), getTrophies(), getQuizList(), getUsers()])
      .then((response) => {
        updateFormWithResponseData(
          response[0],
          response[1],
          response[2],
          response[3]
        );
        setLoadingData(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingData(false);
      });
  }, []);

  const saveChanges = useCallback(async () => {
    setLoadingData(true);

    const itemData = {
      title: formState.title,
      categories: formState.categories.map((val) => val.value)
    };

    if (formState.trophy !== null && formState.trophy !== undefined) {
      itemData['trophy'] = formState.trophy.value;
    }

    if (formState.quiz !== null && formState.quiz !== undefined) {
      itemData['quiz'] = formState.quiz.value;
    }

    if (formState.accountable !== null && formState.accountable !== undefined) {
      itemData['accountable'] = formState.accountable.value;
    }

    if (
      formState.execution_responsible !== null &&
      formState.execution_responsible !== undefined
    ) {
      itemData['execution_responsible'] = formState.execution_responsible.value;
    }

    await videoRepository
      .updateGeneral({ ...itemData, languages: titleFields }, props.content.id)
      .then((response) => {
        console.log('success');
        props.setIsOpen(false);
        props.setContent(response);
      })
      .catch((err) => {
        console.log(err);
        console.log('error');
      });
    setLoadingData(false);
  }, [formState, titleFields]);

  const createNewTrophy = useCallback(
    async (trophyName: string) => {
      setLoadingData(true);

      const create = await trophyRepository.post({ name: trophyName });

      setTrophies([...trophies, create]);
      setFormState({
        ...formState,
        trophy: { value: create.id, label: create.name }
      });

      setLoadingData(false);
    },
    [trophies, formState]
  );

  return (
    <SidebarContainer>
      <Drawer
        BackdropProps={{ invisible: true }}
        style={{ background: 'rgba(255,255,255,0)' }}
        sx={DrawerConfigSx}
        anchor="right"
        open={props.isOpen}
        onClose={() => props.setIsOpen(false)}
      >
        <SidebarDrawer>
          <div className="header">
            <p>{I18N('changeDetails', language.language)}</p>
            <FormButton
              withLoadingInFullScreen={true}
              icon="save"
              color="green"
              smallButton={true}
              text={I18N('save', language.language)}
              onClick={saveChanges}
              isLoading={loadingData}
            />
          </div>
          <div className="box" style={{ marginTop: 5 }}>
            <ViewBox>
              <div style={{ width: '100%' }}>
                <LTextField
                  fullWidth
                  label={I18N('title', language.language)}
                  style={{ marginTop: 0, width: '100%' }}
                  values={titleFields}
                  value={formState.title}
                  sendInputStates={setTitleFields}
                  onChange={(text) =>
                    setFormState({ ...formState, title: text })
                  }
                />
              </div>

              <div className="marginTop20" style={{ width: '100%' }}>
                <p className="viewBoxTitle">
                  {I18N('responsibleUser', language.language)}
                </p>

                <NoCreateSelect
                  styles={{
                    valueContainer: (styles) => {
                      return {
                        ...styles,
                        height: 50
                      };
                    },
                    container: (styles) => {
                      return {
                        ...styles,
                        width: '100%'
                      };
                    }
                  }}
                  theme={(template) => {
                    return {
                      ...template,
                      width: '100%',
                      colors: {
                        ...template.colors,
                        ...colorForSelect(theme)
                      }
                    };
                  }}
                  value={formState.accountable}
                  isClearable={true}
                  options={users.map((user) => {
                    return {
                      value: user.id,
                      label: `${user.firstName} ${user.lastName}`
                    };
                  })}
                  onChange={(item) => {
                    setFormState({ ...formState, accountable: item });
                  }}
                />
              </div>

              <div className="marginTop20" style={{ width: '100%' }}>
                <p className="viewBoxTitle">
                  {I18N('implementationUser', language.language)}
                </p>

                <NoCreateSelect
                  styles={{
                    valueContainer: (styles) => {
                      return {
                        ...styles,
                        height: 50
                      };
                    },
                    container: (styles) => {
                      return {
                        ...styles,
                        width: '100%'
                      };
                    }
                  }}
                  theme={(template) => {
                    return {
                      ...template,
                      width: '100%',
                      colors: {
                        ...template.colors,
                        ...colorForSelect(theme)
                      }
                    };
                  }}
                  value={formState.execution_responsible}
                  isClearable={true}
                  options={users.map((user) => {
                    return {
                      value: user.id,
                      label: `${user.firstName} ${user.lastName}`
                    };
                  })}
                  onChange={(item) => {
                    setFormState({ ...formState, execution_responsible: item });
                  }}
                />
              </div>
            </ViewBox>

            <ViewBox style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="marginTop20">
                <div className="viewBoxTitle" style={{ fontSize: 12 }}>
                  {I18N('whichCategories', language.language)}
                </div>

                <SelectAutocomplete
                  multiple={true}
                  maxTags={3}
                  options={categories.map((category) => {
                    return {
                      value: category.id,
                      label: `${category.name} ${
                        category.user !== undefined
                          ? `(${category?.user?.firstName} ${category?.user?.lastName})`
                          : ''
                      }`
                    };
                  })}
                  value={formState.categories}
                  onChange={(item) =>
                    setFormState({ ...formState, categories: item })
                  }
                />

                {/*<NoCreateSelect*/}
                {/*    styles={{valueContainer: (styles) => {*/}
                {/*            return {*/}
                {/*                ...styles,*/}
                {/*                height: 50,*/}
                {/*                scrollbarWidth: "none",*/}

                {/*                msOverflowStyle: "none",*/}
                {/*                overflowX: "hidden",*/}
                {/*                overflowY: "scroll"*/}
                {/*            };*/}
                {/*        },container: (styles) => {*/}
                {/*            return {*/}
                {/*                ...styles,*/}
                {/*                width: '100%',*/}
                {/*            };*/}
                {/*        }}}*/}
                {/*    theme={(template) => {*/}
                {/*        return {*/}
                {/*            ...template,*/}
                {/*            colors: {*/}
                {/*                ...template.colors,*/}
                {/*                ...colorForSelect(theme)*/}
                {/*            }*/}
                {/*        }*/}
                {/*    }} value={formState.categories} isMulti={true} options={categories.map(category => { return {value: category.id, label: `${category.name} ${category.user !== undefined ? `(${category?.user?.firstName} ${category?.user?.lastName})` : ''}`}})} onChange={(item) => setFormState({...formState, categories: item})} />*/}
              </div>

              <div className="marginTop20">
                <p className="viewBoxTitle">
                  {I18N('nameTrophy', language.language, [
                    { key: 'name', value: I18N('content', language.language) }
                  ])}
                </p>
                <Select
                  styles={{
                    valueContainer: (styles) => {
                      return {
                        ...styles,
                        height: 50
                      };
                    },
                    container: (styles) => {
                      return {
                        ...styles,
                        width: '100%'
                      };
                    }
                  }}
                  theme={(template) => {
                    return {
                      ...template,
                      colors: {
                        ...template.colors,
                        ...colorForSelect(theme)
                      }
                    };
                  }}
                  onCreateOption={createNewTrophy}
                  isClearable={true}
                  isMulti={false}
                  onChange={(change) => {
                    setFormState({ ...formState, trophy: change });
                  }}
                  value={formState.trophy}
                  placeholder={I18N('selectTrophy', language.language)}
                  options={trophies.map((trophy) => ({
                    value: trophy.id,
                    label: trophy.name
                  }))}
                />
              </div>

              <div className="marginTop20">
                <p className="viewBoxTitle">
                  {I18N('selectQuiz', language.language)}
                </p>
                {props.content.type !== 4 ? (
                  <NoCreateSelect
                    styles={{
                      valueContainer: (styles) => {
                        return {
                          ...styles,
                          height: 50
                        };
                      },
                      container: (styles) => {
                        return {
                          ...styles,
                          width: '100%'
                        };
                      }
                    }}
                    theme={(template) => {
                      return {
                        ...template,
                        width: '100%',
                        colors: {
                          ...template.colors,
                          ...colorForSelect(theme)
                        }
                      };
                    }}
                    value={formState.quiz}
                    isClearable={true}
                    options={quizes.map((content) => {
                      return { value: content.id, label: content.name };
                    })}
                    onChange={(item) => {
                      setFormState({ ...formState, quiz: item });
                    }}
                  />
                ) : (
                  <p>
                    {I18N(
                      'quizSelectedCantSelectStandalone',
                      language.language
                    )}
                  </p>
                )}
              </div>
            </ViewBox>
          </div>
        </SidebarDrawer>
      </Drawer>
    </SidebarContainer>
  );
};

export default GeneralContentBar;
