import { I18N } from "../i18n/i18n";
import ViewBox from "../ViewComponents/ViewBox";
import React, {useCallback, useEffect, useState} from "react";
import { useLanguageState } from "../States/LanguageState";
import {VideoRepository} from "../Repositories/VideoRepository";
import {useTheme} from "@mui/material";
import {useBreadCrumb} from "../States/BreadCrumbState";
import {useHistory} from "react-router-dom";
import ShowIcon from "../assets/show.svg";
import HideIcon from "../assets/hide.svg";
import {useGlobalSettingsState} from "../States/GlobalSettings";
import moment from "moment";
import LanguageExporter from "../Helpers/LanguageExporter";
import VideoTypeExporter from "../Helpers/VideoTypeExporter";
const videoRepository = new VideoRepository();

export default function DepartmentSingleAnalytics(props: {contentID: string, onClick: (depID: number) => void; onBack: () => void; activeCompany?: any, onBackFull: () => void;}) {

    const language = useLanguageState(state => state.language);
    const [watchList, setWatchList] = useState(null);
    const [videos, setVideos] = useState([]);
    const theme = useTheme();
    const breadCrumb = useBreadCrumb();
    const [loading,setLoading] = useState(false);
    const history = useHistory();
    const [showUserNames, setShowUserNames] = useState(false);
    const [buttonActivated, setButtonActivated] = useState(true);
    const globalSettings = useGlobalSettingsState(state => state.settings);


    useEffect(() => {

        const findPsudeo = globalSettings.findIndex((i) => i.key === "psudeo");
        const findAnon = globalSettings.findIndex((i) => i.key === "anon");

        if(findAnon !== -1){
            if(Number(globalSettings[findAnon].value)){
                setShowUserNames(false);
                setButtonActivated(false);
            }else{
                setButtonActivated(true);
                if(findPsudeo !== -1){
                    setShowUserNames(!Number(globalSettings[findPsudeo].value))
                }else{
                    setShowUserNames(false);
                }
            }
        }else{
            setButtonActivated(true);
            if(findPsudeo !== -1){
                setShowUserNames(!Number(globalSettings[findPsudeo].value))
            }else{
                setShowUserNames(false);
            }
        }



    }, [globalSettings]);
    useEffect(() => {

        if(props.activeCompany){
            breadCrumb.setCrumb([
                {
                    title: I18N('analytics', language),
                    onClick: undefined
                },
                {
                    title: I18N('companies', language),
                    onClick: () => props.onBackFull(),
                },
                {
                    title: props.activeCompany.name,
                    onClick: props.onBack,
                },
                {
                    title: watchList?.name,
                    onClick: undefined
                }
            ]);


        }else{
            breadCrumb.setCrumb([
                {
                    title: I18N('analytics', language),
                    onClick: undefined
                },
                {
                    title: I18N('departments', language),
                    onClick: () => props.onBack()
                },
                {
                    title: watchList?.name,
                    onClick: undefined
                }
            ]);

        }

        breadCrumb.setTitle(watchList?.name);

        breadCrumb.setBackButton(props.onBack);
        breadCrumb.setChildren([{
            title: '',
            color: "transparent",
            isLoading: loading,
            isFullLoading: true,
            onClick: undefined
        }]);

    }, [watchList, loading]);

    const loadData = useCallback(async () => {
        setLoading(true);
        const watchListData = await videoRepository.getWatchListSingleDep(+props.contentID);
        setLoading(false);
        setWatchList(watchListData.depData);
        setVideos(watchListData.videos)
    }, [props.contentID]);

    useEffect(() => {
        loadData().then(undefined);
    }, [loadData]);


    const getColor = useCallback((percent: number) => {

        if(percent >= 75){
            return 'green';
        }else if(percent >= 50) {
            return 'lightGreen'
        }else if(percent >= 25){
            return 'orange';
        }else if(percent >= 10){
            return 'lightOrange';
        }else if(isNaN(percent)){
            return ''
        } else {
            return 'red'
        }


    }, []);


    const returnUserData = useCallback((userInner, userId, uShow) => {
        if((userInner !== null && userInner !== undefined)) {
            if (!moment(userInner.archived_at).isBefore(moment(new Date()).subtract(1, "year").add("day", 1))) {
                if (uShow) {
                    return userInner.firstName + ' - ' + userInner.lastName
                }
            } else {
                return userId
            }
        }
        return userId

    }, []);

// showUserNames ? watchList?.firstName + " "+watchList?.lastName : watchList?.id
    return (
        <div style={{marginTop: 30}}>
            <div className="flex-row">
                <ViewBox>
                    <div className="tableView">
                        <thead className="sticky" >
                        <tr className="head sticky"  >
                            <th style={{width: '70%', backgroundColor: theme.palette.mode === 'dark' && theme.palette.background.paper, color: theme.palette.text.primary}} className="tableTh"><div className="ml-10">{I18N('video', language)}</div></th>
                            <th style={{width: '51%', backgroundColor: theme.palette.mode === 'dark' && theme.palette.background.paper, color: theme.palette.text.primary}} className="tableTh"><div className="ml-10">Anteil gesehener Videos</div></th>

                        </tr>
                        </thead>
                        <ul className="ranking">
                            {watchList?.video_departments.map((videoData) => {
                                const userWatched = undefined;//watchList.seen_videos.find((video) => video.video_id === videoData.id);
                                const userIDs = watchList.users.map((user) => user.id);
                                const seenWatchlistFromUsers = videoData.videos.seen_videos.filter((seen) => {
                                    return userIDs.includes(seen.user_id);
                                });
                                return (
                                    <li className="rankingBars" onClick={() => {}}>
                                        <div className="videoTitle" style={{width: '70%'}}>
                                            {LanguageExporter(videoData.videos, language, 'video_titles', 'title', 'title')} ({VideoTypeExporter(videoData.videos.type)})
                                        </div>
                                        <div className="endFlex">
                                            <div className="rankingText">{seenWatchlistFromUsers.length} / {userIDs.length} ({isNaN((seenWatchlistFromUsers.length / userIDs.length * 100)) ? '0'  : (seenWatchlistFromUsers.length / userIDs.length * 100).toFixed(0)}%)</div>
                                            <div className={`rankingBar ${getColor(seenWatchlistFromUsers.length / userIDs.length * 100)}`} style={{width: seenWatchlistFromUsers.length / userIDs.length * 100 + '%'}}>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                            {videos.map((video) => {
                                const userIDs = watchList.users.map((user) => user.id);
                                const seenWatchlistFromUsers = video.seen_videos.filter((seen) => {
                                    return userIDs.includes(seen.user_id);
                                });
                                return (
                                    <li className="rankingBars" onClick={() => {}}>
                                        <div className="videoTitle" style={{width: '70%'}}>
                                            {LanguageExporter(video, language, 'video_titles', 'title', 'title')} ({VideoTypeExporter(video.type)})
                                        </div>
                                        <div className="endFlex">
                                            <div className="rankingText">{seenWatchlistFromUsers.length} / {userIDs.length} ({isNaN((seenWatchlistFromUsers.length / userIDs.length * 100)) ? '0' : (seenWatchlistFromUsers.length / userIDs.length * 100).toFixed(0)}%)</div>
                                            <div className={`rankingBar ${getColor(seenWatchlistFromUsers.length / userIDs.length * 100)}`} style={{width: seenWatchlistFromUsers.length / userIDs.length * 100 + '%'}}>
                                            </div>
                                        </div>
                                    </li>
                                )

                            })}

                        </ul>
                    </div>
                </ViewBox>
                <ViewBox title={''} rightComponent={(
                    <div className="endFlex flex-row" style={{marginLeft: "auto", width: "auto", cursor: "pointer", justifyItems: "flex-end", float: "right", fontWeight: "bold", fontSize: 15, lineHeight: 1.8, marginRight:30}} onClick={() => buttonActivated && setShowUserNames(!showUserNames)}>
                        {buttonActivated && (<>{I18N("showUsernames", language)} {showUserNames ? <img src={ShowIcon} width={25} height={25} /> : <img src={HideIcon} width={25} height={25} /> }</>)}
                    </div>
                )}>
                    <div className="tableView" style={{marginTop: 20}}>
                        <thead className="sticky" >
                        <tr className="head sticky"  >
                            <th style={{width: '70%', backgroundColor: theme.palette.mode === 'dark' && theme.palette.background.paper, color: theme.palette.text.primary}} className="tableTh"><div className="ml-10">{I18N('user', language)}</div></th>
                            <th style={{width: '51%', backgroundColor: theme.palette.mode === 'dark' && theme.palette.background.paper, color: theme.palette.text.primary}} className="tableTh"><div className="ml-10">Anteil gesehener Videos</div></th>
                        </tr>
                        </thead>
                        <ul className="ranking">
                            {watchList?.users.map((user) => {
                                let requiredVideos = watchList.video_departments.map((video) => video.videos);
                                requiredVideos = [...requiredVideos, ...videos];
                                const videoIDS = requiredVideos.map((req) => req.id);
                                const watchedVideos = user.seen_videos.filter((video) => {
                                    return videoIDS.includes(video.video_id);
                                })
                                return (
                                    <li className="rankingBars" onClick={() => {}}>
                                        <div className="videoTitle" style={{width: '70%'}}>

                                            {returnUserData(user, user.id, showUserNames)}

                                            {/*{showUserNames ? user?.firstName + " "+user?.lastName : user.id}*/}
                                        </div>
                                        <div className="endFlex">
                                            <div className="rankingText">{watchedVideos.length} / {requiredVideos.length} ({isNaN((watchedVideos.length / requiredVideos.length * 100)) ? '0' : (watchedVideos.length / requiredVideos.length * 100).toFixed(0)}%)</div>
                                            <div className={`rankingBar ${getColor(watchedVideos.length / requiredVideos.length * 100)}`} style={{width: watchedVideos.length / requiredVideos.length * 100 + '%'}}>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </ViewBox>

            </div>

        </div>
    )
}
