import create from 'zustand';
import React, {ReactElement} from "react";
export type ChildrenType = ReactElement | {title: string, onClick: (props?: any) => void, color: 'dark' | 'yellow' | 'green' | 'lightGreen' | 'transparent' | 'red', smallText?: string, isSmall?: boolean, isLoading?: boolean, isFullLoading?: boolean, isBackButton?: boolean, isDeleteButton?: boolean,  icon?: 'archive' | 'plus' | 'save', key?: string}[] | null;
type CrumbType = {title: string, onClick: (props?: any) => void};

type BreadCrumbStateType = {
    title: string;
    crumbs: CrumbType[];
    children: ChildrenType;
    setChildren: (items: ChildrenType) => void;
    setAdditionalCrumb: (crumb: CrumbType) => void;
    setCrumb: (crumb: CrumbType[]) => void;
    setTitle: (title: string) => void;
    backButton: () => void | null;
    setBackButton: (button: () => void | null) => void;
    deleteLast: (matchingText: string) => void;
};

export const useBreadCrumb = create<BreadCrumbStateType>(set => {
    return ({
        title: '',
        crumbs: [],
        children: null,
        backButton: null,
        setBackButton: (button) => {
            return set({backButton: button})
        },
        deleteLast: (matchingText: string) => set((state) => {
           const lastCrumb = state.crumbs[state.crumbs.length - 1];
           if(lastCrumb.title !== matchingText){
               state.crumbs.splice(state.crumbs.length - 1, 1);
                return set({crumbs: [...state.crumbs]});
           }

        }),
        setCrumb: (crumb) => {
            console.log("updating crumb", crumb)
            return set({crumbs: crumb})
        },
        setAdditionalCrumb: (crumb) => set((state) => {
            console.log("adding additional crumb", crumb)
            const isSet = state.crumbs.findIndex((crumbInner) => crumbInner.title === crumb.title)
            if(isSet === -1){
                return ({ crumbs: [...state.crumbs, crumb] })
            }
        }),
        setChildren: (items) => set((state) => {
            console.log("setting child", items)
            if(items === null){
                return ({children: null});
            }
            return ({children: items});

            // if(state.children !== null && !React.isValidElement(state.children) && !React.isValidElement(items)){
            //     const keysOnly = state.children.map((i) => i.key);
            //     const keyNew = items.map((i) => i.key);
            //
            //     const keyNewCheck = keyNew.some(r=> keysOnly.indexOf(r) >= 0)
            //     if(!keyNewCheck){
            //         return ({children: items});
            //     }
            // }else {
            //     return ({children: items});
            // }
        }),
        setTitle: (title) => {
            console.log("setting title", title)
            return set({title});
        }
    });
});
