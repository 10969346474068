import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import {ConfigurationsRepository} from "../Repositories/Configurations";
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import loadingIcon from '../assets/loading.svg'
const searchRepository = new ConfigurationsRepository();


const SearchBackdrop = styled('div')(() => ({
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2000,
    background: 'rgba(0,0,0,0.2)',
    '& [hidden]': {
        display: 'none'
    }
}));

const SearchInput = styled('div')(() => ({
    position: 'relative',
    top: '20%',
    height: '80%',
    width: '600px',
    margin: '0 auto',
    display: 'block',
    '& svg': {
        position: 'absolute',
        top: '7px',
        left: '20px',
        zIndex: 2
    },
    '& input': {
        height: '50px',
        background: '#C4C4C4',
        boxShadow: 'inset 0px -2px 16px rgba(0, 0, 0, 0.25)',
        borderRadius: '20px',
        width: '100%',
        paddingLeft: '65px',
        paddingRight: '20px',
        fontSize: '20px',
        border: 0,
        position: 'relative',
        zIndex: 1
    },
    '& input:focus-visible': {
        outline: 0
    },
    '& b': {
        fontSize: '20px',
        margin: '20px 0',
        display: 'block'
    }
}));

const SearchResponse = styled('div')(() => ({
    background: 'rgba(62, 70, 73, 0.8)',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    color: '#fff',
    marginBottom: '20px',
    marginTop: '-20px',
    padding: '20px 20px 40px',
    maxHeight: 'calc(100% - 32px)',
    overflowY: 'auto',
    cursor: 'pointer',
    '& > div > div': {
        margin: '20px 10px'
    },
    '& p': {
        fontSize: '20px',
        margin: 0
    },
    '& span': {
        textDecoration: 'underline',
        display: 'block',
        marginTop: '10px',
        cursor: 'pointer'
    }
}));

const SmartProposal = styled('div')(() => ({
    maxHeight: 'calc(100% - 122px)',
    overflowY: 'auto'
}));

const SmartProposalBox = styled('div')(() => ({
    background: 'rgba(62, 70, 73, 0.8)',
    borderRadius: '10px',
    color: '#fff',
    marginBottom: '20px',
    padding: '20px 20px 40px',
    '& p': {
        fontSize: '20px',
        margin: '0 0 20px 0'
    },
    '& > div': {
        margin: '0 10px'
    }
}));

const SmartProposalGrid = styled(Grid)(() => ({
    paddingLeft: '10px',
    '& span': {
        textDecoration: 'underline',
        color: '#FFE16A',
        width: '130px',
        display: 'block',
        cursor: 'pointer'
    }
}));

interface ISearchDialog {
    opened: boolean;
    setOpened: Dispatch<SetStateAction<boolean>>;
}
let timeout = null;
const SearchDialog = (props: ISearchDialog) => {
    const { opened, setOpened } = props;
    const [searchResponse, setSearchResponse] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const language = useLanguageState(state => state.language);
    const inputRef = useRef(null);
    const [noResponse, setNoResponse] = useState(false);

    useEffect(() => {

        inputRef.current?.focus()

    }, [inputRef, opened]);

    const loadData = async (event: any) => {
        setLoading(true);
        if(timeout !== null)
            clearTimeout(timeout);

         timeout = setTimeout(async () => {
            await searchRepository.getSearchResults(event.target.value).then((e: any[]) => {
                if(e.length === 0){
                    setNoResponse(true);
                }else{
                    setNoResponse(false);
                }
                setSearchResponse(e);
            }).catch((e) => {
                console.log(e);
            });
            setLoading(false);

        }, 600);

        //setSearchResponse(event.target.value ? search : []);
    };
    return (
        <SearchBackdrop hidden={!opened} onClick={() => setOpened(false)}>
            <SearchInput onClick={event => event.stopPropagation()}>
                {/*<Icon size={35} color="#fff" name="SearchIcon" />*/}
                <input type="text" ref={inputRef} onKeyUp={loadData} />
                {loading && (<div style={{position: "absolute", right: 10, top: 0, zIndex:99}}><img width={50} height={50}  src={loadingIcon} /></div>)}

                {!loading && noResponse && (
                    <SearchResponse>
                        <div style={{textAlign: "center", fontSize: 25, marginTop: 30}}>
                            {I18N('noResults', language)}
                        </div>
                    </SearchResponse>
                )}

                {searchResponse.length ? (
                    <SearchResponse>
                        {searchResponse.map((item, index) => (

                                <div key={index}>
                                    {index > 0 && <hr />}
                                    <Link style={{textDecoration: "none"}} to={item.link}>
                                        <p style={{color: "#fff", textDecoration: "none"}}>{I18N(item.title, language)}</p>
                                        <span style={{color: "#fff", textDecoration: "none"}}>{item.link.split("/").slice(1).map((i) => I18N(i.toLowerCase(), language)).join(" > ")}</span>
                                    </Link>
                                </div>

                        ))}
                    </SearchResponse>
                ) : (
                    <div style={{display: "none"}}>
                        {/*<b>Smart assistant proposals</b>*/}
                        {/*<SmartProposal>*/}
                        {/*    {data.map((item, _index) => (*/}
                        {/*        <SmartProposalBox key={_index}>*/}
                        {/*            <p>{item.title}</p>*/}
                        {/*            <div>*/}
                        {/*                {item.values.map((_item, index) => (*/}
                        {/*                    <div key={index}>*/}
                        {/*                        {index > 0 && <hr />}*/}
                        {/*                        <SmartProposalGrid container>*/}
                        {/*                            <Grid item xs>*/}
                        {/*                                {_item.title}*/}
                        {/*                            </Grid>*/}
                        {/*                            <Grid item xs="auto">*/}
                        {/*                                <span>{_item.action}</span>*/}
                        {/*                            </Grid>*/}
                        {/*                        </SmartProposalGrid>*/}
                        {/*                    </div>*/}
                        {/*                ))}*/}
                        {/*            </div>*/}
                        {/*        </SmartProposalBox>*/}
                        {/*    ))}*/}
                        {/*</SmartProposal>*/}
                    </div>
                )}
            </SearchInput>
        </SearchBackdrop>
    );
};

export default SearchDialog;
